const styles = {
  container: {
    // color: "#006AFF",
    color: "#1B1C1B",
    fontWeight: 600,
    fontSize: "3rem",
    alignSelf: "center",
    fontStyle: "italic",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20%",
    marginLeft: "5rem",
  },
};

export default styles;
