/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Chip,
  FormHelperText,
  IconButton,
  ListItemText,
  MenuItem,
  TypographyProps,
} from "@mui/material";
import Select, { SelectProps } from "@mui/material/Select";
import styles from "./styles";
import { Item } from "./types";
import ClearIcon from "@mui/icons-material/Clear";

const CustomSelect = ({
  id,
  label,
  name,
  items,
  value,
  onChange,
  errorMessage = "",
  multiple = false,
  multiline = false,
  variant = "outlined",
  handleDeleteChip,
  fontWeight,
  placeholder,
  style,
  sx,
  size,
  colorCircle,
  optionStyle,
  ...props
}: SelectProps & {
  items: Item[];
  errorMessage?: string | undefined;
  handleCheckItem?: (
    checked: boolean,
    item: number | string | number[] | string[]
  ) => void;
  handleDeleteChip?: (value: number | string) => void;
  colorCircle?: boolean;
  variant?: "standard" | "outlined" | "filled" | undefined;
  fontWeight?: string;
  optionStyle?: TypographyProps<"span", { component?: "span" }>;
}) => {
  return (
    <div style={{ ...style }}>
      <Select
        {...props}
        displayEmpty
        size={size}
        value={value}
        onChange={onChange}
        multiple={multiple}
        multiline={multiline}
        variant={variant}
        renderValue={(selected: any) => {
          if (selected === -1 || selected?.length === 0) {
            return <em style={styles.selectPlaceholder}>{placeholder}</em>;
          }
          if (multiple) {
            return multiline ? (
              <Box sx={styles.multipleBox}>
                {selected?.map((selectValue: any) => (
                  <Chip
                    key={selectValue}
                    label={items.find((it) => it.value === selectValue)?.name}
                    icon={
                      <div
                        style={
                          colorCircle
                            ? styles.colorCircle(
                                items
                                  .find((it) => it.value === selectValue)
                                  ?.code?.toLowerCase() || "",
                                "0px",
                                "50%",
                                "1.2vh",
                                "1px solid"
                              )
                            : {}
                        }
                      />
                    }
                    deleteIcon={
                      <IconButton
                        data-testid="ClearIcon"
                        title="ClearIcon"
                        onMouseDown={(event) => event.stopPropagation()}
                        style={styles.deleteIcon}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    }
                    clickable
                    onDelete={() => {
                      if (handleDeleteChip) {
                        handleDeleteChip(selectValue);
                      }
                    }}
                    data-testid="selected-option-chip"
                  />
                ))}
              </Box>
            ) : (
              <Box sx={styles.multipleBox}>
                {selected?.slice(-1).map((selectValue: any) => (
                  <div style={styles.chipContainer}>
                    <Chip
                      key={selectValue}
                      label={items.find((it) => it.value === selectValue)?.name}
                      icon={
                        <div
                          style={
                            colorCircle
                              ? styles.colorCircle(
                                  items
                                    .find((it) => it.value === selectValue)
                                    ?.code?.toLowerCase() || "",
                                  "0px",
                                  "50%",
                                  "1.2vh",
                                  "1px solid"
                                )
                              : {}
                          }
                        />
                      }
                      deleteIcon={
                        <IconButton
                          data-testid="ClearIcon"
                          title="ClearIcon"
                          onMouseDown={(event) => event.stopPropagation()}
                          style={styles.deleteIcon}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      }
                      clickable
                      onDelete={() => {
                        if (handleDeleteChip) {
                          handleDeleteChip(selectValue);
                        }
                      }}
                      data-testid="selected-option-chip"
                    />

                    {selected.length > 1 ? (
                      <Chip
                        data-testid="selected-option-chip-length"
                        style={styles.selectedChip}
                        label={`+${selected.length - 1}`}
                      />
                    ) : null}
                  </div>
                ))}
              </Box>
            );
          }
          return items.find((it) => it.value === selected)?.name;
        }}
        sx={{
          ...styles.select_field_text,
          ...sx,
        }}
        inputProps={{
          "aria-label": "Without label",
          "data-testid": id,
          sx: styles.inputPropStyle,
        }}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {colorCircle && (
              <div
                style={styles.colorCircle(
                  item.name.toLocaleLowerCase(),
                  "2vh",
                  "50%",
                  "1.2vh",
                  "1px solid"
                )}
                data-testid="color-circle"
              />
            )}
            <ListItemText
              primaryTypographyProps={optionStyle}
              primary={item.name}
            />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="error_text" sx={styles.error_message}>
        {errorMessage}
      </FormHelperText>
    </div>
  );
};

export default CustomSelect;
