import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./styles";
import { IClient } from "../Clients/types";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import Header from "../Header";
import { object, string } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import { getApi, postApiWithAuth, putApiWithAuth } from "../../utils/apis";
import CustomSelect from "../../components/SelectField";
import InputField from "../../components/InputField";
import getAPIUrl from "../../config";
import toastStyles from "../../components/Toast/styles";
import Toast from "../../components/Toast";
import { ILocationFormValues, ILocationRes } from "./types";
import { useTranslation } from "react-i18next";

const LocationForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const isEdit = location.pathname.includes("edit-location");

  const { id } = useParams();

  const validationSchema = object({
    planType: string().min(1, "Please select plan Type"),
    clientId: string().min(1, "Please select Client Name"),
    name: string().min(1, "Please provide Location Name"),
    streetName: string().min(1, "Please provide Street Name"),
    streetNumber: string().min(1, "Please provide Street Number"),
    phone: string().min(1, "Please provide phone"),
    email: string().min(1, "Please provide email"),
    city: string().min(1, "Please provide city"),
    postalCode: string().min(1, "Please provide postalCode"),
    floorNumber: string().optional(),
    houseNumber: string().optional(),
  });

  const defaultValues: ILocationFormValues = {
    planType: "",
    clientId: "",
    name: "",
    streetName: "",
    streetNumber: "",
    floorNumber: "",
    houseNumber: "",
    phone: "",
    email: "",
    city: "",
    postalCode: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data: ILocationFormValues) => {
    setLoading(true);
    try {
      isEdit
        ? await putApiWithAuth(`${getAPIUrl()}/api/v1/locations/${id}`, data)
        : await postApiWithAuth(`${getAPIUrl()}/api/v1/locations`, data);
      setSuccessToastMsg(
        `Location ${isEdit ? "updated" : "created"} successfully`
      );
      setSuccessToast(true);
      reset(defaultValues);
      setTimeout(() => {
        setLoading(false);
        navigate("/locations");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const getClients = async () => {
      setLoading(true);
      try {
        const clientsData = await getApi(`${getAPIUrl()}/api/v1/clients/name`);
        setClients(clientsData.data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };

    const getLocationDetails = async () => {
      try {
        const locationDetailsData: ILocationRes = await getApi(
          `${getAPIUrl()}/api/v1/locations/${id}`
        );

        reset({
          planType: locationDetailsData.data.planType,
          clientId: locationDetailsData.data.clientId,
          name: locationDetailsData.data.name,
          streetName: locationDetailsData.data.streetName,
          streetNumber: locationDetailsData.data.streetNumber,
          floorNumber: locationDetailsData.data.floorNumber ?? undefined,
          houseNumber: locationDetailsData.data.houseNumber ?? undefined,
          phone: locationDetailsData.data.phone,
          email: locationDetailsData.data.email,
          city: locationDetailsData.data.city,
          postalCode: locationDetailsData.data.postalCode,
        });
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    getClients();
    if (isEdit) {
      getLocationDetails();
    }
  }, [id, isEdit, reset]);

  const clientsList =
    clients &&
    clients.map((client) => ({ name: client.name, value: client.id }));

  const planTypes = [
    { name: "Free", value: "Free" },
    { name: "Standard", value: "Standard" },
    { name: "Premium", value: "Premium" },
  ];

  const handleCancel = () => {
    reset(defaultValues);
    navigate("/locations");
  };

  return (
    <Grid sx={styles.container}>
      <Header
        name={isEdit ? t("editLocation") : t("newLocation")}
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.locationDetailsContainer}>
        <Typography sx={styles.subHeading}>{t("locationDetails")}</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testId="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <Box component="form" sx={styles.formContainer} onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 10 }}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="planType"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="planType"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder={t("planType")}
                      items={planTypes}
                      error={!!errors.planType?.message}
                      errorMessage={errors.planType?.message}
                      label={t("planType")}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="clientId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="clientId"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder={t("clientName")}
                      items={clientsList}
                      error={!!errors.clientId?.message}
                      errorMessage={errors.clientId?.message}
                      label={t("clientName")}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="locationName"
                      label={t("locationName")}
                      placeholder={t("locationName")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("name", "")}
                      error={!!errors.name?.message}
                      errorMessage={errors.name?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="streetName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="streetName"
                      label={t("streetName")}
                      placeholder={t("streetName")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("streetName", "")}
                      error={!!errors.streetName?.message}
                      errorMessage={errors.streetName?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="streetNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="streetNumber"
                      label={t("streetNumber")}
                      placeholder={t("streetNumber")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("streetNumber", "")}
                      error={!!errors.streetNumber?.message}
                      errorMessage={errors.streetNumber?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="floorNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="floorNumber"
                      label={t("floorNumber")}
                      placeholder={t("floorNumber")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("floorNumber", "")}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="houseNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="houseNumber"
                      label={t("houseNumber")}
                      placeholder={t("houseNumber")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("houseNumber", "")}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="phone"
                      label={t("phone")}
                      placeholder={t("phone")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("phone", "")}
                      error={!!errors.phone?.message}
                      errorMessage={errors.phone?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="email"
                      label={t("email")}
                      placeholder={t("email")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("email", "")}
                      error={!!errors.email?.message}
                      errorMessage={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="city"
                      label={t("city")}
                      placeholder={t("city")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("city", "")}
                      error={!!errors.city?.message}
                      errorMessage={errors.city?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="postalCode"
                      label={t("postalCode")}
                      placeholder={t("postalCode")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("postalCode", "")}
                      error={!!errors.postalCode?.message}
                      errorMessage={errors.postalCode?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 10 }}
              sx={styles.buttonsGrid}
            >
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={styles.cancelButton}
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={styles.saveButton}
                >
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Toast
        id="add-location-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="add-location-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default LocationForm;
