import { Outlet } from "react-router-dom";
import SideBar from "../Sidebar";
import styles from "./styles";
import { Box } from "@mui/material";

const Layout = () => {
  return (
    <Box style={styles.layoutContainer}>
      <SideBar />
      <Box style={styles.outlet}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
