import { Box, Button, CircularProgress, FormHelperText, Grid, Typography } from "@mui/material";
import styles from "../AddUser/styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getApi,
  postApiWithAuthFormData,
  putApiWithAuth,
} from "../../utils/apis";
import getAPIUrl from "../../config";
import { IRole, IUserResponse } from "../AddUser/types";
import {
  getApiErrorMessage,
  getUserClientId,
  getUserRole,
} from "../../utils/commonHelpers";
import { object, string, z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/InputField";
import CustomSelect from "../../components/SelectField";
import User from "../../assets/user.svg";
import { IFormValues } from "./types";
import { IClient } from "../Clients/types";
import { ILocation } from "../Locations/types";
import Header from "../Header";
import Toast from "../../components/Toast";
import toastStyles from "../../components/Toast/styles";
import { useTranslation } from "react-i18next";
import { MuiTelInput } from "mui-tel-input";

const EditUser = () => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<IClient[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const userRole = getUserRole();
  const userCLientId = getUserClientId();

  const { id } = useParams();

  const validationSchema = object({
    firstName: string().min(1, "Please provide First Name"),
    lastName: string().min(1, "Please provide Last Name"),
    email: string({ required_error: "Please provide an email" }).email({
      message: "Please provide a valid email address",
    }),
    phone: z.string().regex(/^\+48\d{9}$/, "Phone number must be in the format +48 followed by 9 digits"),
    roleId: string().min(1, "Please select User Type"),
    imageUrl: string().url().optional(),
    profileImage: z
      .instanceof(File, { message: "Please upload profile pic" })
      .optional(),
    clientId: string().optional(),
    locationId: string().optional(),
  });

  const defaultValues: IFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roleId: "",
    imageUrl: "",
    profileImage: undefined,
    clientId: "",
    locationId: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const uploadFile = async (fileToUpload: File) => {
    try {
      const formData = new FormData();
      formData.append("files", fileToUpload);

      const res = await postApiWithAuthFormData(
        `${getAPIUrl()}/api/v1/files/user-images`,
        formData
      );
      return res.data;
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
      }
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const { profileImage, ...rest } = data;
    let imageUrl = "";
    if (profileImage) {
      const uploadedImage = await uploadFile(profileImage);
      imageUrl = uploadedImage[0].imageUrl.replace("/user-images/", "");
    }
    try {
      const res = await putApiWithAuth(`${getAPIUrl()}/api/v1/users/${id}`, {
        ...rest,
        imageUrl,
      });

      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      reset(defaultValues);
      setTimeout(() => {
        setLoading(false);
        navigate("/users");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesData = await getApi(`${getAPIUrl()}/api/v1/roles/name`);
        setRoles(rolesData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };

    const getUserDetails = async () => {
      try {
        const userDetailsData: IUserResponse = await getApi(
          `${getAPIUrl()}/api/v1/users/${id}`
        );

        const profile =
          userDetailsData.data.imageUrl && userDetailsData.data.imageUrl !== ""
            ? await fetch(
              `${getAPIUrl()}/api/v1/files/view/user-images?file=${userDetailsData.data.imageUrl
              }`
            ).then((response) => {
              return response.blob();
            })
            : undefined;

        reset({
          firstName: userDetailsData.data.firstName,
          lastName: userDetailsData.data.lastName,
          phone: userDetailsData.data.phone,
          email: userDetailsData.data.email,
          roleId: userDetailsData.data.roleId,
          profileImage: profile ? new File([profile], "userImage") : undefined,
          clientId: userDetailsData.data.clientId,
          locationId: userDetailsData.data.locationId,
        });
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    const getClients = async () => {
      try {
        const clientsData = await getApi(`${getAPIUrl()}/api/v1/clients/name`);
        setClients(clientsData.data);
        if (userRole === "Client Admin") {
          setValue("clientId", userCLientId || "");
        }
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    const getLocations = async () => {
      try {
        const locationsData = await getApi(
          `${getAPIUrl()}/api/v1/locations/name`
        );
        setLocations(locationsData.data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    getRoles();
    getClients();
    getLocations();
    getUserDetails();
  }, [id, reset, setValue, userCLientId, userRole]);

  const rolesList =
    roles && roles.map((role) => ({ name: role.name, value: role.id }));
  const clientsList =
    clients &&
    clients.map((client) => ({ name: client.name, value: client.id }));

  const formatPhoneNumber = (value: string): string => {
    // Remove all non-digit characters except the leading +
    const digits = value.replace(/[^\d+]/g, '');
    // Ensure it starts with +48 and is followed by 9 digits
    if (!digits.startsWith('+48')) {
      return '+48';
    }
    return `+48${digits.slice(3, 12)}`;
  };

  const handleCancel = () => {
    reset(defaultValues);
    navigate("/users");
  };

  const watchFields = watch(["roleId", "clientId"]);
  return (
    <Grid sx={styles.container}>
      <Header
        name={t("userEdit")}
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.userDetailsContainer}>
        <Typography sx={styles.subHeading}>{t("userDetails")}</Typography>

        {loading ? (
          <Typography sx={styles.spinner} data-testid="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <Box component="form" sx={styles.formContainer} onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 10 }}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="firstName"
                      label={t("firstName")}
                      placeholder={t("firstName")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("firstName", "")}
                      error={!!errors.firstName?.message}
                      errorMessage={errors.firstName?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="lastName"
                      label={t("lastName")}
                      placeholder={t("lastName")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("lastName", "")}
                      error={!!errors.lastName?.message}
                      errorMessage={errors.lastName?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <>
                      <MuiTelInput
                        {...field}
                        id="phone"
                        label={t("phone")}
                        placeholder={t("phone")}
                        fullWidth
                        defaultCountry="PL"
                        preferredCountries={['PL']} size="small"
                        forceCallingCode
                        disableDropdown
                        value={formatPhoneNumber(field.value)}
                        onChange={(newValue) => {
                          const formattedValue = formatPhoneNumber(newValue);
                          setValue("phone", formattedValue, { shouldValidate: true });
                          field.onChange(formattedValue);
                        }}
                        error={!!errors.phone?.message}
                      />
                      {errors.phone && (
                        <FormHelperText error>
                          {errors.phone.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="email"
                      label={t("email")}
                      placeholder={t("email")}
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("email", "")}
                      error={!!errors.email?.message}
                      errorMessage={errors.email?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="roleId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="roleId"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder={t("userType")}
                      items={rolesList}
                      error={!!errors.roleId?.message}
                      errorMessage={errors.roleId?.message}
                      label={t("userType")}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="profileImage"
                  control={control}
                  render={({ field }) => (
                    <Grid sx={styles.profileImageGrid}>
                      <img
                        src={
                          field.value ? URL.createObjectURL(field.value) : User
                        }
                        alt="profile"
                        style={styles.profileImage}
                      />
                      <Button component="label" sx={styles.uploadImageButton}>
                        {t("uploadImage")}
                        <input
                          type="file"
                          id="upload-profile-picture"
                          accept=".png,.jpg,.jpeg"
                          hidden
                          onChange={(e) =>
                            field.onChange(
                              e.target.files && (e.target.files[0] as File)
                            )
                          }
                        />
                      </Button>
                    </Grid>
                  )}
                />
              </Grid>

              {watchFields[0] !== "" &&
                roles.filter((rec) =>
                  userRole === "Admin" ? rec.id === watchFields[0] : true
                )[0].name !== "Admin" && (
                  <>
                    <Grid item md={6} xs={12}>
                      <Controller
                        name="clientId"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            id="clientId"
                            onChange={(e) => field.onChange(e.target.value)}
                            size="small"
                            fullWidth
                            placeholder={t("client")}
                            items={clientsList}
                            error={!!errors.clientId?.message}
                            errorMessage={errors.clientId?.message}
                            label={t("client")}
                            disabled={userRole === "Client Admin"}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="locationId"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            id="locationId"
                            onChange={(e) => field.onChange(e.target.value)}
                            size="small"
                            fullWidth
                            placeholder={t("location")}
                            items={
                              watchFields[1] === ""
                                ? []
                                : locations
                                  .filter(
                                    (rec) => rec.clientId === watchFields[1]
                                  )
                                  .map((location) => ({
                                    name: location.name,
                                    value: location.id,
                                  }))
                            }
                            error={!!errors.locationId?.message}
                            errorMessage={errors.locationId?.message}
                            label={t("location")}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
            </Grid>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 10 }}
              sx={styles.buttonsGrid}
            >
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={styles.cancelButton}
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={styles.saveButton}
                >
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Toast
        id="edit-user-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="edit-user-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default EditUser;
