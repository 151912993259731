import { Navigate } from "react-router-dom";
import { getAccessToken, getUserRole } from "../../utils/commonHelpers";
import AccessDenied from "../AccessDenied";

const ProtectedRoute = ({
  children,
  adminRoute = true,
}: {
  children: JSX.Element;
  adminRoute?: boolean;
}) => {
  const token = getAccessToken();
  const role = getUserRole();
  if (!token) {
    return <Navigate to="/" replace />;
  }
  if (adminRoute && role !== "Admin") {
    return <AccessDenied />;
  }
  return children;
};
export default ProtectedRoute;
