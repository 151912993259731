import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import XCircle from "../../assets/x-circle.svg";
import styles from "./styles";

const InputField = ({
  id,
  name,
  value,
  label,
  type,
  placeholder,
  showCross,
  errorMessage,
  handleCrossClick,
  InputProps,
  ...props
}: TextFieldProps & { showCross?: boolean } & { errorMessage?: string } & {
  handleCrossClick?: () => void;
}) => {
  return (
    <>
      <TextField
        {...props}
        id={id}
        name={name}
        value={value}
        label={label}
        type={type}
        placeholder={placeholder}
        InputProps={
          InputProps
            ? InputProps
            : showCross
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleCrossClick}
                      sx={styles.inputPropsIcon}
                    >
                      {
                        <img
                          alt="x-circle"
                          src={XCircle}
                          style={styles.inputPropsIcon}
                        />
                      }
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      <FormHelperText sx={styles.errorMessage}>{errorMessage}</FormHelperText>
    </>
  );
};

export default InputField;
