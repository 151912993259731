const styles = {
  label: {
    marginBottom: "8px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
  },
  select: {
    [`& fieldset`]: {
      borderRadius: 4,
      border: "1px solid #C3CCD9",
    },
  },
  item: {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    lineHeight: "21px",
    padding: "12px",
    color: "#001533",
  },
  placeholder: {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    lineHeight: "21px",
    color: "#a2a2a2",
  },
  input: {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    lineHeight: "21px",
    padding: "12px",
  },
  select_field_text: {
    background: "#FFFFFF",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "15px",
    color: "#1A1A1A",
    textAlign: "start" as unknown as "start",
  },
  error_message: {
    color: "#ED0000",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontSize: "0.75",
    lineHeight: "1.66",
  },
  color: {
    color: "#ED0000",
  },
  colorCircle: (
    bgColor: string,
    mr: string,
    br: string,
    padding: string,
    border: string
  ) => ({
    padding,
    marginRight: mr,
    display: "inline-block",
    backgroundColor: bgColor,
    borderRadius: br,
    border,
  }),
  selectPlaceholder: {
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#B1B1B1",
  },
  multipleBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: 0.5,
  },
  deleteIcon: {
    height: "1rem",
    width: "1rem",
  },
  chipContainer: {
    display: "flex",
    gap: "0.4vh",
  },
  selectedChip: {
    background: "#DCDCDC",
    borderRadius: "0px",
  },
  inputPropStyle: {
    display: "flex",
    alignItems: "center",
    "&.MuiInput-input": { padding: "4px 0px 0px" },
  },
};

export default styles;
