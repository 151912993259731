import {
  Badge,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import styles from "./styles";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { IHeaderProps, INotification, INotificationsResponse } from "./types";
import { useEffect, useRef, useState } from "react";
import getAPIUrl from "../../config";
import { getApi, patchApiWithAuth, putApiWithAuth } from "../../utils/apis";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import { AxiosResponse } from "axios";
import { SimpleMenu } from "../../components/Menu";
import { useNavigate } from "react-router-dom";
import { socket } from "../../socket/instance";
import i18next from "i18next";

const Header = ({
  name,
  setErrorToast,
  setErrorToastMsg,
  setSuccessToast,
  setSuccessToastMsg,
}: IHeaderProps) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [count, setCount] = useState<number>(0);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const buttonRef = useRef(null);

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAllReadButton = async () => {
    try {
      const res = await putApiWithAuth(
        `${getAPIUrl()}/api/v1/notifications/`,
        {}
      );

      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      setAnchorEl(null);
      setCount(0);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
      }
    }
  };

  const handleNotificationClick = async (notification: INotification) => {
    try {
      await patchApiWithAuth(
        `${getAPIUrl()}/api/v1/notifications/${notification.messageId}`,
        {}
      );

      setSuccessToastMsg("Notification marked as Read");
      setSuccessToast(true);
      setAnchorEl(null);
      navigate(`/edit-parcel/${notification.data.entityId}`);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
      }
    }
  };

  const getNotifications = async () => {
    try {
      const notificationsData: AxiosResponse<INotificationsResponse> =
        await getApi(`${getAPIUrl()}/api/v1/notifications`);
      setNotifications(notificationsData.data.notifications);
      setCount(notificationsData.data.count);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        console.log({ errorMsg });
      }
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    socket.on("notification", async () => {
      await getNotifications();
      setAnchorEl(buttonRef.current);
    });
  }, []);

  return (
    <Box style={styles.container}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={styles.stack}
      >
        <Typography sx={styles.headerText}>{name}</Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => i18next.changeLanguage(e.target.value)}
            defaultValue={i18next.language}
          >
            <FormControlLabel value="en" control={<Radio />} label="EN" />
            <FormControlLabel value="pl" control={<Radio />} label="PL" />
          </RadioGroup>

          <Badge badgeContent={count} color="primary">
            <IconButton
              id="notifications-button"
              component="button"
              ref={buttonRef ?? null}
              sx={styles.notificationContainer}
              onClick={(e) => handleClick(e)}
              disabled={count <= 0}
            >
              <NotificationsNoneOutlinedIcon
                fontSize="small"
                sx={styles.notificationIcon}
              />
            </IconButton>
          </Badge>
          <SimpleMenu
            id="notifications"
            anchorEl={anchorEl}
            handleCloseMenu={handleClose}
            title={
              <Grid sx={styles.notificationMenuTitleContainer}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography sx={styles.notificationTitle}>
                    Notifications({count})
                  </Typography>
                  {count > 0 && (
                    <Button
                      sx={styles.markAllButton}
                      onClick={handleAllReadButton}
                    >
                      Mark all as read
                    </Button>
                  )}
                </Stack>
                <Divider />
              </Grid>
            }
            menuItems={notifications.map((rec) => ({
              item: (
                <Box id={rec.messageId}>
                  <Typography sx={styles.notificationTitle}>
                    {rec.notification.title}
                  </Typography>
                  <Typography sx={styles.notificationBody}>
                    {rec.notification.body}
                  </Typography>
                </Box>
              ),
              handleClick: () => handleNotificationClick(rec),
            }))}
          />
        </Stack>
      </Stack>
      <Divider />
    </Box>
  );
};

export default Header;
