import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import styles from "./styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getApi, putApiWithAuth } from "../../utils/apis";
import getAPIUrl from "../../config";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import { object, string } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IClientRes, IFormValues } from "./types";
import Toast from "../../components/Toast";
import toastStyles from "../../components/Toast/styles";
import ClientForm from "./ClientForm";

const EditClient = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");

  const navigate = useNavigate();

  const { id } = useParams();

  const validationSchema = object({
    name: string().min(1, "Please provide Company Name"),
    companyTaxId: string().min(1, "Please provide Company TaxId"),
    email: string({ required_error: "Please provide an email" }).email({
      message: "Please provide a valid email address",
    }),
    phone: string().regex(/^\d{10}$/, "Please provide a mobile number"),
    address: string().min(1, "Please provide Address"),
    postalCode: string().min(1, "Please provide Postal Code"),
    city: string().min(1, "Please provide City"),
  });

  const defaultValues: IFormValues = {
    name: "",
    address: "",
    city: "",
    postalCode: "",
    companyTaxId: "",
    email: "",
    phone: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const { ...rest } = data;
    try {
      const res = await putApiWithAuth(`${getAPIUrl()}/api/v1/clients/${id}`, {
        ...rest,
      });

      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      reset(defaultValues);
      setTimeout(() => {
        setLoading(false);
        navigate("/clients");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const getClientDetails = async () => {
      try {
        const clientDetailsData: IClientRes = await getApi(
          `${getAPIUrl()}/api/v1/clients/${id}`
        );

        reset({
          name: clientDetailsData.data.name,
          companyTaxId: clientDetailsData.data.companyTaxId,
          phone: clientDetailsData.data.phone,
          email: clientDetailsData.data.email,
          address: clientDetailsData.data.address,
          city: clientDetailsData.data.city,
          postalCode: clientDetailsData.data.postalCode,
        });
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    getClientDetails();
  }, [id, reset]);

  const handleCancel = () => {
    reset(defaultValues);
    navigate("/clients");
  };
  return (
    <Grid sx={styles.container}>
      <Typography sx={styles.heading}>Client Edit</Typography>
      <Box sx={styles.clientDetailsContainer}>
        <Typography sx={styles.subHeading}>Client details</Typography>

        {loading ? (
          <Typography sx={styles.spinner} data-testid="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <ClientForm
            control={control}
            errors={errors}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            handleCancel={handleCancel}
            isEditing={true}
          />
        )}
      </Box>
      <Toast
        id="edit-client-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="edit-client-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default EditClient;
