export const UserStatuses = [
  { id: 0, name: "Delete", color: "#ed0000" },
  { id: 1, name: "Active", color: "#22C55E", backgroundColor: "#ECFDF3" },
  { id: 2, name: "Inactive", color: "#475569", backgroundColor: "#EDEDED" },
];

export const ParcelStatuses = [
  { id: 0, name: "InStorage", color: "#ed0000" },
  { id: 1, name: "Issued", color: "#22C55E", backgroundColor: "#ECFDF3" },
  { id: 2, name: "Returned", color: "#475569", backgroundColor: "#EDEDED" },
];

