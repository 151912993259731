import { Box, Grid, IconButton, Typography } from "@mui/material";
import { IParcel, IParcelsRes } from "./types";
import { IColumn } from "../../components/Table/types";
import CustomTable from "../../components/Table";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { getApi } from "../../utils/apis";
import getAPIUrl from "../../config";
import Header from "../Header";
import MoreIcon from "../../assets/more.svg";
import { ParcelStatuses, UserStatuses } from "../../utils/constants";
import styles from "./styles";
import TableHeader from "../../components/TableHeader";
import tableStyles from "../../components/Table/styles";
import { useNavigate } from "react-router-dom";
import toastStyles from "../../components/Toast/styles";
import Toast from "../../components/Toast";
import EditIcon from "../../assets/pen-square.svg";
import { MenuItems } from "../../components/Menu/types";
import { SimpleMenu } from "../../components/Menu";
import IssueParcel from "../../assets/issue-parcel.svg";
import { socket } from "../../socket/instance";

const getParcelList = async (
  page: number,
  search?: string,
  sortBy?: keyof IParcel,
  sortOrder?: "asc" | "desc" | string
): Promise<Error | IParcelsRes> => {
  let url = `api/v1/parcels?pageIndex=${page}&pageSize=10&sortBy=${
    sortBy ?? "createdAt"
  }&sortOrder=${sortOrder ?? "desc"}`;

  if (search) {
    url = `${url}&search=${search}`;
  }
  try {
    const resultData: AxiosResponse<IParcelsRes> = await getApi(
      `${getAPIUrl()}/${url}`
    );
    return resultData.data;
  } catch (error) {
    return error as Error;
  }
};

const Parcels = () => {
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [fetchAgain, setFetchAgain] = useState(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");
  const [rowId, setRowId] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setRowId("");
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setRowId(id);
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const columns: IColumn<IParcel>[] = [
    { dataKey: "parcelNumber", title: "Parcel Number", sortable: true },

    {
      dataKey: "pickupLocationId",
      title: "Location",
      render: ({ row }: { row: IParcel }) => {
        return (
          <Typography sx={tableStyles.tableBodyCellText}>
            {row.pickupLocation.location.name}
          </Typography>
        );
      },
      sortable: true,
    },
    {
      dataKey: "userId",
      title: "User",
      render: ({ row }: { row: IParcel }) => {
        return (
          <Typography
            sx={tableStyles.tableBodyCellText}
          >{`${row.user.firstName} ${row.user.lastName}`}</Typography>
        );
      },
      sortable: true,
    },
    { dataKey: "deliveryTime", title: "Delivery Time", sortable: true },
    { dataKey: "pickupTime", title: "Pickup Time", sortable: true },
    { dataKey: "deliveryCompany", title: "Delivery Company", sortable: true },

    {
      dataKey: "parcelStatus",
      title: "Parcel Status",
      sortable: true,

      render: ({ row }: { row: IParcel }) => {
        const parcelStatus = ParcelStatuses.find(
          (statusObj) => statusObj.name === row.parcelStatus
        );
        if (parcelStatus) {
          return (
            <Typography
              component="span"
              sx={{
                color: parcelStatus.color,
                fontWeight: 500,
                lineHeight: "1.125rem",
                fontSize: "0.75rem",
              }}
            >
              {parcelStatus.name}
            </Typography>
          );
        } else {
          return null;
        }
      },
    },

    {
      dataKey: "status",
      title: "Status",
      sortable: true,

      render: ({ row }: { row: IParcel }) => {
        const status = UserStatuses.find(
          (statusObj) => statusObj.name === row.status
        );
        if (status) {
          return (
            <Typography
              component="span"
              sx={{
                color: status.color,
                fontWeight: 500,
                lineHeight: "1.125rem",
                fontSize: "0.75rem",
              }}
            >
              {status.name}
            </Typography>
          );
        } else {
          return null;
        }
      },
    },

    {
      dataKey: "id",
      title: "Actions",
      sortable: false,
      render: ({ row }: { row: IParcel }): ReactElement => {
        const actionItems: MenuItems[] = [
          {
            item: (
              <Grid sx={styles.actionItemGrid}>
                <img alt="edit-icon" src={EditIcon} style={styles.actionIcon} />
                <Typography sx={styles.actionText("#1B1C1B")}>
                  Edit Data
                </Typography>
              </Grid>
            ),
            handleClick: () => handleEditAction(row.id),
          },
        ];
        return (
          <Box key={row.id}>
            <IconButton key={row.id} onClick={(e) => handleClick(e, row.id)}>
              {<img alt="more" src={MoreIcon} style={styles.actionIcon} />}
            </IconButton>
            {rowId === row.id && (
              <SimpleMenu
                id={row.id}
                anchorEl={anchorEl}
                handleCloseMenu={handleClose}
                menuItems={actionItems}
              />
            )}
          </Box>
        );
      },
    },

    {
      dataKey: "createdAt",
      title: "Issue",
      sortable: false,
      render: ({ row }: { row: IParcel }) => {
        return (
          <img
            src={IssueParcel}
            alt={`issue-parcel-${row.id}`}
            style={styles.parcelIcon}
            onClick={() => handleIssueAction(row.id)}
          />
        );
      },
    },
  ];

  const handleSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearchValue(val);
    setPage(1);
    setFetchAgain(!fetchAgain);
  };

  const handleClearSearch = async () => {
    setSearchValue("");
    setPage(1);
    setFetchAgain(!fetchAgain);
  };
  const handleAdd = async () => {
    navigate("/add-parcel");
  };

  const handleEditAction = (id: string) => {
    navigate(`/edit-parcel/${id}`);
  };

  const handleIssueAction = (id: string) => {
    navigate(`/issue-parcel/${id}`);
  };

  useEffect(() => {
    socket.on("notification", async () => {
      setFetchAgain(!fetchAgain);
    });
  }, [fetchAgain]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        name="Parcels"
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.tableContainer}>
        <TableHeader
          headerTitle="Parcel"
          headerDesc="Parcel"
          handleClearSearch={handleClearSearch}
          handleSearchChange={handleSearchChange}
          buttonName="Add Parcel"
          searchValue={searchValue}
          handleAdd={handleAdd}
        />
        <CustomTable
          page={page}
          setPage={setPage}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          fetchAgain={fetchAgain}
          setErrorToast={setErrorToast}
          setErrorToastMsg={setErrorToastMsg}
          columns={columns}
          rowKey="id"
          initialSortField={"createdAt"}
          fetchTableData={getParcelList}
        />
      </Box>
      <Toast
        id="get-parcels-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Box>
  );
};

export default Parcels;
