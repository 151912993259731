const styles = {
  inputField: {
    border: "1px solid",
    borderColor: "#E2E8F0",
    height: "2.8rem",
    width: "15rem",
  },
  errorMessage: {
    color: "#ED0000",
  },
  inputPropsIcon: {
    height: "1rem",
    width: "1rem",
  },
};

export default styles;
