const styles = {
  stackContainer: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  headerTitle: {
    color: "#101828",
    fontWeight: 600,
    lineHeight: "2rem",
    fontSize: "1.125rem",
  },
  headerDesc: {
    color: "#667085",
    fontWeight: 400,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
  },
  inputPropsIcon: {
    height: "1rem",
    width: "1rem",
  },
  headerTitleGrid: { gap: "0.25rem" },
  searchFilterStack: { width: "36rem", height: "2.5rem" },
  filterIcon: { width: "1.25rem", height: "1.25rem" },
  filterButton: {
    textTransform: "none",
    color: "#344054",
    fontWeight: 500,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
  },
};

export default styles;
