import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import reportWebVitals from "./reportWebVitals";
import enTranslation from "./locales/en.json";
import plTranslation from "./locales/pl.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // Default language
  resources: {
    en: {
      translation: enTranslation,
    },
    pl: {
      translation: plTranslation,
    },
  },
});
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
