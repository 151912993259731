const styles = {
  layoutContainer: {
    display: "flex",
    height: "100vh",
    overflowY: "auto" as unknown as "auto",
  },

  outlet: {
    paddingTop: "1rem",
    width: "100%",
    // height: "100%",
  },
};

export default styles;
