export const generateRandomPassword = () => {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const specialChars = "!@#$%^&*()";
  const numberChars = "0123456789";
  const passwordLength = 8;
  let password = "";

  // Ensure at least one uppercase letter, one special character, and one number
  password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
  password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));
  password += numberChars.charAt(Math.floor(Math.random() * numberChars.length));

  // Fill the remaining characters
  for (let i = password.length; i < passwordLength; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  // Shuffle the password to ensure randomness
  password = password.split('').sort(() => 0.5 - Math.random()).join('');
  
  return password;
};

