import { Box, Grid, Typography, IconButton } from "@mui/material";
import { ILocation, ILocationsRes } from "./types";
import { IColumn } from "../../components/Table/types";
import CustomTable from "../../components/Table";
import { ChangeEvent, ReactElement, useState } from "react";
import { AxiosResponse } from "axios";
import { deleteApiWithAuth, getApi } from "../../utils/apis";
import getAPIUrl from "../../config";
import Header from "../Header";
import MoreIcon from "../../assets/more.svg";
import { UserStatuses } from "../../utils/constants";
import styles from "./styles";
import TableHeader from "../../components/TableHeader";
import tableStyles from "../../components/Table/styles";
import { useNavigate } from "react-router-dom";
import { SimpleMenu } from "../../components/Menu";
import { MenuItems } from "../../components/Menu/types";
import EditIcon from "../../assets/pen-square.svg";
import TerminateIcon from "../../assets/calendar-clock.svg";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import Toast from "../../components/Toast";
import toastStyles from "../../components/Toast/styles";
import { useTranslation } from "react-i18next";

const getLocationList = async (
  page: number,
  search?: string,
  sortBy?: keyof ILocation,
  sortOrder?: "asc" | "desc" | string
): Promise<Error | ILocationsRes> => {
  let url = `api/v1/locations?pageIndex=${page}&pageSize=10&sortBy=${
    sortBy ?? "createdAt"
  }&sortOrder=${sortOrder ?? "desc"}`;

  if (search) {
    url = `${url}&search=${search}`;
  }
  try {
    const resultData: AxiosResponse<ILocationsRes> = await getApi(
      `${getAPIUrl()}/${url}`
    );
    return resultData.data;
  } catch (error) {
    return error as Error;
  }
};

const Locations = () => {
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [fetchAgain, setFetchAgain] = useState(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");
  const [rowId, setRowId] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setRowId("");
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setRowId(id);
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEditAction = (id: string) => {
    navigate(`/edit-location/${id}`);
  };
  const handleDeleteAction = async (id: string) => {
    try {
      const res = await deleteApiWithAuth(
        `${getAPIUrl()}/api/v1/locations/${id}`
      );
      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      setRowId("");
      setAnchorEl(null);
      setFetchAgain(!fetchAgain);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
      }
    }
  };

  const columns: IColumn<ILocation>[] = [
    { dataKey: "planType", title: "Plan Type", sortable: true },
    {
      dataKey: "clientId",
      title: "Client",
      render: ({ row }: { row: ILocation }) => {
        return (
          <Typography sx={tableStyles.tableBodyCellText}>
            {row.client.name}
          </Typography>
        );
      },
      sortable: true,
    },
    { dataKey: "name", title: "Name", sortable: true },
    { dataKey: "streetName", title: "Street Name", sortable: true },
    { dataKey: "streetNumber", title: "Street Number", sortable: true },
    { dataKey: "city", title: "City", sortable: true },
    { dataKey: "postalCode", title: "Postal Code", sortable: true },

    {
      dataKey: "status",
      title: "Status",
      sortable: true,

      render: ({ row }: { row: ILocation }) => {
        const status = UserStatuses.find(
          (statusObj) => statusObj.name === row.status
        );
        if (status) {
          return (
            <Typography
              component="span"
              sx={{
                color: status.color,
                fontWeight: 500,
                lineHeight: "1.125rem",
                fontSize: "0.75rem",
              }}
            >
              {status.name}
            </Typography>
          );
        } else {
          return null;
        }
      },
    },

    {
      dataKey: "id",
      title: "Actions",
      sortable: false,
      render: ({ row }: { row: ILocation }): ReactElement => {
        const actionItems: MenuItems[] = [
          {
            item: (
              <Grid sx={styles.actionItemGrid}>
                <img alt="edit-icon" src={EditIcon} style={styles.actionIcon} />
                <Typography sx={styles.actionText("#1B1C1B")}>
                  Edit Data
                </Typography>
              </Grid>
            ),
            handleClick: () => handleEditAction(row.id),
          },
          {
            item: (
              <Grid sx={styles.actionItemGrid}>
                <img
                  alt="terminate-icon"
                  src={TerminateIcon}
                  style={styles.actionIcon}
                />
                <Typography sx={styles.actionText("#EF4444")}>
                  Terminate
                </Typography>
              </Grid>
            ),
            handleClick: () => handleDeleteAction(row.id),
          },
        ];
        return (
          <Box key={row.id}>
            <IconButton key={row.id} onClick={(e) => handleClick(e, row.id)}>
              {<img alt="more" src={MoreIcon} style={styles.actionIcon} />}
            </IconButton>
            {rowId === row.id && (
              <SimpleMenu
                id={row.id}
                anchorEl={anchorEl}
                handleCloseMenu={handleClose}
                menuItems={actionItems}
              />
            )}
          </Box>
        );
      },
    },
  ];

  const handleSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearchValue(val);
    setPage(1);
    setFetchAgain(!fetchAgain);
  };

  const handleClearSearch = async () => {
    setSearchValue("");
    setPage(1);
    setFetchAgain(!fetchAgain);
  };

  const handleAdd = async () => {
    navigate("/add-location");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        name={t("locations")}
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.tableContainer}>
        <TableHeader
          headerTitle={t("location")}
          headerDesc={t("location")}
          handleClearSearch={handleClearSearch}
          handleSearchChange={handleSearchChange}
          buttonName={t("addLocation")}
          searchValue={searchValue}
          handleAdd={handleAdd}
        />
        <CustomTable
          page={page}
          setPage={setPage}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          fetchAgain={fetchAgain}
          setErrorToast={setErrorToast}
          setErrorToastMsg={setErrorToastMsg}
          columns={columns}
          rowKey="id"
          initialSortField={"createdAt"}
          fetchTableData={getLocationList}
        />
      </Box>
      <Toast
        id="get-locations-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="locations-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Box>
  );
};

export default Locations;
