const styles = {
  container: {
    width: "100%",
    height: "6rem",
    display: "flex",
    flexDirection: "column" as unknown as "column",
    gap: "1.5rem",
  },
  headerText: {
    fontWeight: 600,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    color: "#000000",
    paddingLeft: "2rem",
  },
  stack: {
    paddingRight: "2rem",
  },
  notificationContainer: {
    borderRadius: "0.5rem",
    borderWidth: "1px",
    background: "#F1F5F9",
    color: "#64748B",
    borderColor: "#CBD5E1",
    height: "2.5rem",
    width: "2.5rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  notificationIcon: {
    color: "#64748B",
    margin: 1.25,
  },
  notificationTitle: {
    color: "#1B1C1B",
    fontWeight: 600,
    fontSize: "1rem",
  },
  notificationBody: {
    color: "#334155",
    fontWeight: 400,
    fontSize: "0.8rem",
  },
  notificationMenuTitleContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    gap: "0.5rem",
  },
  markAllButton: {
    textTransform: "none",
  },
};
export default styles;
