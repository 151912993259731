import axios, { AxiosRequestHeaders } from "axios";
import getAPIUrl from "../config";
import { getAccessToken } from "./commonHelpers";

// use this for calling public apis
const axiosInstance = axios.create({
  baseURL: getAPIUrl(),
  headers: {
    "content-type": "application/json",
  },
  responseType: "json",
});

// use this for calling apis which need authentication
export const axiosInstanceWithAuth = axios.create({
  baseURL: getAPIUrl(),
  headers: {
    "content-type": "application/json",
  },
  responseType: "json",
});

export const axiosInstanceWithAuthBlob = axios.create({
  baseURL: getAPIUrl(),
  headers: {
    "content-type": "application/json",
  },
  responseType: "blob",
});

export const axiosInstanceWithAuthFormData = axios.create({
  baseURL: getAPIUrl(),
  headers: {
    "content-type": "multipart/form-data",
  },
  responseType: "json",
});
axiosInstanceWithAuth.interceptors.request.use((request) => {
  const accToken = getAccessToken();
  if (accToken) {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${accToken ?? ""}`;
    } else {
      request.headers = {
        Authorization: `Bearer ${accToken ?? ""}`,
      } as AxiosRequestHeaders;
    }
  }
  return request;
});

axiosInstanceWithAuthFormData.interceptors.request.use((request) => {
  const accToken = getAccessToken();
  if (accToken) {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${accToken ?? ""}`;
    } else {
      request.headers = {
        Authorization: `Bearer ${accToken ?? ""}`,
      } as AxiosRequestHeaders;
    }
  }
  return request;
});

axiosInstanceWithAuthBlob.interceptors.request.use((request) => {
  const accToken = getAccessToken();
  if (accToken) {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${accToken ?? ""}`;
    } else {
      request.headers = {
        Authorization: `Bearer ${accToken ?? ""}`,
      } as AxiosRequestHeaders;
    }
  }
  return request;
});

export default axiosInstance;
