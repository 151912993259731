import { Menu, MenuItem, Typography } from "@mui/material";
import styles from "./styles";
import { SimpleMenuProps } from "./types";

export const SimpleMenu = ({
  id,
  anchorEl,
  handleCloseMenu,
  menuItems,
  title,
}: SimpleMenuProps) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      id={id}
      key={id}
      open={open}
      onClose={handleCloseMenu}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={styles.container}
    >
      {title ? title : <Typography sx={styles.actionsText}>Actions</Typography>}
      {menuItems.map((menuItem, index) => (
        <MenuItem key={index} onClick={menuItem.handleClick}>
          {menuItem.item}
        </MenuItem>
      ))}
    </Menu>
  );
};
