const styles = {
  container: {},
  "& .MuiPaper-root": {
    boxShadow: "none !important",
  },
  actionsText: {
    color: "#94A3B8",
    fontWeight: 500,
    fontSize: "0.75rem",
    lineHeight: "1rem",
    paddingLeft: "1rem",
  },
};

export default styles;
