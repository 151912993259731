import { Button, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { PaginationProps } from "./types";

const CustomPagination = ({
  totalCount,
  page,
  pageSize,
  currentPageDataCount,
  handlePageChange,
}: PaginationProps) => {
  const recordCountFrom = page > 1 ? (page - 1) * pageSize + 1 : 1;
  const recordCountTo = recordCountFrom - 1 + currentPageDataCount;

  return (
    <Stack
      justifyContent="space-between"
      spacing={2}
      direction="row"
      alignItems="center"
      sx={styles.container}
    >
      <Typography sx={styles.recordCountText}>
        {`${recordCountFrom} - ${recordCountTo} of ${totalCount} items`}
      </Typography>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        gap="1rem"
      >
        <Button
          sx={styles.button}
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Button
          sx={styles.button}
          onClick={() => handlePageChange(page + 1)}
          disabled={recordCountTo === totalCount}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default CustomPagination;
