import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./styles";
import { Controller, useForm } from "react-hook-form";
import { RefinementCtx, ZodIssueCode, object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/InputField";
import { useEffect, useState } from "react";
import getAPIUrl from "../../config";
import { getApi, postApiWithAuth } from "../../utils/apis";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../components/SelectField";
import { ILocation } from "../Locations/types";
import { IUser } from "../Users/types";
import { IClient } from "../Clients/types";
import toastStyles from "../../components/Toast/styles";
import Toast from "../../components/Toast";
import Header from "../Header";
import { IRole } from "../AddUser/types";
import { IPickerAddForm, IUserForm } from "./types";

const AddPicker = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientId, setClientId] = useState<string>("");
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");
  const [showNewUserForm, setShowNewUserForm] = useState<boolean>(false);
  const [roles, setRoles] = useState<IRole[]>([]);

  const navigate = useNavigate();

  const userValidationSchema = object({
    firstName: string().min(1, "Please provide First Name"),
    lastName: string().min(1, "Please provide Last Name"),
    email: string({ required_error: "Please provide an email" }).email({
      message: "Please provide a valid email address",
    }),
    phone: string().regex(/^\d{10}$/, "Please provide a mobile number"),
    roleId: string().min(1, "Please select User Type"),
    password: string().regex(
      /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#!@$%^&*]).{8,}$/,
      "Passwords must be at least 8 characters long and contain at least 1 capital letter, special character and number"
    ),
  });

  const validationSchema = object({
    locationId: string().min(1, "Please select Location Name"),
    userId: string().optional(),
    buildingName: string().optional(),
    floorNumber: string().min(1, "Please provide floor number"),
    identificationNumber: string().min(
      1,
      "Please provide identification number"
    ),
    newUser: userValidationSchema.optional(),
  }).superRefine((val, ctx: RefinementCtx) => {
    if (!val.userId && !val.newUser) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        message: "Please select user or add new",
        path: ["userId"],
      });
    }
  });

  const userDefaultvalues: IUserForm = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roleId: "",
    password: "",
  };

  const defaultValues: IPickerAddForm = {
    clientId: "",
    locationId: "",
    userId: "",
    buildingName: "",
    floorNumber: "",
    identificationNumber: "",
    newUser: undefined,
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      let user;
      const { newUser, ...rest } = data;
      if (!data.userId && newUser) {
        const res = await postApiWithAuth(`${getAPIUrl()}/api/v1/users`, {
          ...data.newUser,
        });
        user = res.data.id;
      } else {
        user = rest.userId;
      }
      const reqBody = { ...rest, userId: user };

      await postApiWithAuth(`${getAPIUrl()}/api/v1/pickup-locations`, reqBody);
      setSuccessToastMsg("Record created successfully");
      setSuccessToast(true);
      reset(defaultValues);
      setTimeout(() => {
        setLoading(false);
        navigate("/pickers");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const getClients = async () => {
      try {
        const clientsData = await getApi(`${getAPIUrl()}/api/v1/clients/name`);
        setClients(clientsData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    const getLocations = async (clientId?: string) => {
      try {
        const locationsData = clientId
          ? await getApi(
            `${getAPIUrl()}/api/v1/locations/name?clientId=${clientId}`
          )
          : await getApi(`${getAPIUrl()}/api/v1/locations/name`);
        setLocations(locationsData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    const getUsers = async () => {
      try {
        const usersData = await getApi(
          `${getAPIUrl()}/api/v1/users/name?roleName=Picker`
        );
        setUsers(usersData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };

    const getRoles = async () => {
      try {
        const rolesData = await getApi(
          `${getAPIUrl()}/api/v1/roles/name?search=Picker`
        );
        setRoles(rolesData.data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    getClients();
    getLocations(clientId);
    getUsers();
    getRoles();
  }, [clientId]);

  const clientsList =
    clients &&
    clients.map((client) => ({ name: client.name, value: client.id }));
  const locationsList =
    locations &&
    locations.map((location) => ({ name: location.name, value: location.id }));

  const usersList =
    users &&
    users.map((user) => ({
      name: user.email,
      value: user.id,
    }));

  const handleCancel = () => {
    reset(defaultValues);
    navigate("/pickers");
  };

  const rolesList =
    roles && roles.map((role) => ({ name: role.name, value: role.id }));

  return (
    <Grid sx={styles.container}>
      <Header
        name="New Picker"
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.pickerDetailsContainer}>
        <Typography sx={styles.subHeading}>Picker details</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testId="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <Box component="form" sx={styles.formContainer} onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 10 }}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="clientId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="clientId"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        const cId = (e.target as HTMLSelectElement).value;
                        setClientId(cId);
                        setLocations([]);
                      }}
                      size="small"
                      fullWidth
                      placeholder="Client Name"
                      items={clientsList}
                      error={!!errors.clientId?.message}
                      errorMessage={errors.clientId?.message}
                      label="Client Name"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="locationId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="locationId"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder="Location Name"
                      items={locationsList}
                      error={!!errors.locationId?.message}
                      errorMessage={errors.locationId?.message}
                      label="Location Name"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Autocomplete<{ name: string; value: string }>
                        id="userId"
                        size="small"
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Picker" />
                        )}
                        options={usersList}
                        getOptionLabel={(option) => option.name}
                        onChange={(_e, v) => {
                          if (v === null) {
                            return;
                          }
                          field.onChange(v.value.toString());
                          setShowNewUserForm(false);
                          setValue("newUser", undefined);
                        }}
                        noOptionsText={
                          <Button
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                              setShowNewUserForm(true);
                              setValue("userId", undefined);
                              setValue("newUser", userDefaultvalues);
                              setValue("newUser.roleId", rolesList[0].value);
                            }}
                          >
                            {" "}
                            + Add new
                          </Button>
                        }
                      />
                      <FormHelperText id="error_text" sx={styles.errorMessage}>
                        {errors.userId?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="buildingName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="buildingName"
                      label="Building Name"
                      placeholder="Building Name"
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("buildingName", "")}
                      error={!!errors.buildingName?.message}
                      errorMessage={errors.buildingName?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="floorNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="floorNumber"
                      label="Floor Number"
                      placeholder="Floor Number"
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("floorNumber", "")}
                      error={!!errors.floorNumber?.message}
                      errorMessage={errors.floorNumber?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="identificationNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="identificationNumber"
                      label="Identification Number"
                      placeholder="Identification Number"
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() =>
                        setValue("identificationNumber", "")
                      }
                      error={!!errors.identificationNumber?.message}
                      errorMessage={errors.identificationNumber?.message}
                    />
                  )}
                />
              </Grid>

              {showNewUserForm && (
                <>
                  <Grid item md={12} xs={12}>
                    <Typography sx={styles.subHeading}>User details</Typography>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="newUser.firstName"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          id="firstName"
                          label="First Name"
                          placeholder="First Name"
                          type="text"
                          showCross
                          fullWidth
                          size="small"
                          handleCrossClick={() =>
                            setValue("newUser.firstName", "")
                          }
                          error={!!errors.newUser?.firstName?.message}
                          errorMessage={errors.newUser?.firstName?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="newUser.lastName"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          id="lastName"
                          label="Last Name"
                          placeholder="Last Name"
                          type="text"
                          showCross
                          fullWidth
                          size="small"
                          handleCrossClick={() =>
                            setValue("newUser.lastName", "")
                          }
                          error={!!errors.newUser?.lastName?.message}
                          errorMessage={errors.newUser?.lastName?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="newUser.phone"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          id="phone"
                          label="Phone"
                          placeholder="Phone"
                          type="text"
                          showCross
                          fullWidth
                          size="small"
                          handleCrossClick={() => setValue("newUser.phone", "")}
                          error={!!errors.newUser?.phone?.message}
                          errorMessage={errors.newUser?.phone?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="newUser.email"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          id="email"
                          label="Email"
                          placeholder="Email"
                          type="text"
                          showCross
                          fullWidth
                          size="small"
                          handleCrossClick={() => setValue("newUser.email", "")}
                          error={!!errors.newUser?.email?.message}
                          errorMessage={errors.newUser?.email?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="newUser.roleId"
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          id="roleId"
                          onChange={(e) => field.onChange(e.target.value)}
                          size="small"
                          fullWidth
                          placeholder="User Type"
                          items={rolesList}
                          error={!!errors.newUser?.roleId?.message}
                          errorMessage={errors.newUser?.roleId?.message}
                          label="User Type"
                          disabled
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="newUser.password"
                      control={control}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          id="password"
                          label="Password"
                          placeholder="Password"
                          type="text"
                          showCross
                          fullWidth
                          size="small"
                          handleCrossClick={() =>
                            setValue("newUser.password", "")
                          }
                          error={!!errors.newUser?.password?.message}
                          errorMessage={errors.newUser?.password?.message}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 10 }}
              sx={styles.buttonsGrid}
            >
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={styles.cancelButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={styles.saveButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Toast
        id="add-picker-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="add-picker-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default AddPicker;
