import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import MyPickPointer from "../assets/mypick-portier.svg";
import Warstwa from "../assets/warstwa.svg";
import styles from "./styles";
import ChevronRight from "../assets/chevron-right.svg";
import { useState, useEffect } from "react";
import { getApi } from "../utils/apis";
import getAPIUrl from "../config";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getApiErrorMessage } from "../utils/commonHelpers";

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export const VerifyUser = () => {
  const [message, setMessage] = useState<string>("");
  const query = useQuery();
  const token = query.get('token');
  const { t } = useTranslation();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const res = await getApi(`${getAPIUrl()}/api/v1/users/verify-email?token=${token}`);
        setMessage(res.data.message);
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = getApiErrorMessage(error);
          setMessage(errorMessage);
        }

      }
    };

    if (token) {
      verifyEmail();
    } else {
      setMessage("Invalid verification token.");
    }
  }, [token]);

  return (
    <Grid sx={styles.container}>
      <img
        src={MyPickPointer}
        alt="my-pick-pointer"
        style={styles.myPickPointer}
      />
      <Grid sx={styles.loginFormContainer}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => i18next.changeLanguage(e.target.value)}
          defaultValue={i18next.language}
        >
          <FormControlLabel value="en" control={<Radio />} label="EN" />
          <FormControlLabel value="pl" control={<Radio />} label="PL" />
        </RadioGroup>
        <img src={Warstwa} alt="warstwa" style={styles.warstwa} />
        <Typography sx={styles.loginHeaderText}>myPick Verify Email</Typography>
        <Typography sx={styles.loginHeaderText}>{message}</Typography>
        <Typography sx={styles.problemsText}>{t("anyProblems")}</Typography>

        <Button
          sx={styles.helpButton}
          endIcon={<img alt="chevron-right" src={ChevronRight} />}
        >
          {t("weCanHelpYou")}
        </Button>
      </Grid>
    </Grid>
  );
};
