const styles = {
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    border: "1px solid",
    borderColor: "#EAECF0",
    borderRadius: "8px",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  inputPropsIcon: {
    height: "1rem",
    width: "1rem",
  },
  actionItemGrid: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
  },
  actionIcon: {
    height: "1.5rem",
    width: "1.5rem",
  },
  actionText: (color: string) => ({
    color: color,
    fontWeight: 600,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  }),
  parcelIcon: {
    height: "1.25rem",
    width: "1.25rem",
  },
};

export default styles;
