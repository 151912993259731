const styles = {
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    border: "1px solid",
    borderColor: "#EAECF0",
    borderRadius: "8px",
  },
  tableHeadRow: {
    background: "#F8FAFC",
  },
  tableHeaderSort: {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "#64748B",
    lineHeight: "1.2rem",
  },
  tableBody: {
    overflowY: "auto",
  },
  tableBodyCell: {
    maxWidth: "10rem",
    wordWrap: "break-word",
  },
  tableBodyCellText: {
    fontWeight: 550,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
    color: "#0F172A",
  },
  arrowIcon: {
    paddingLeft: "0.25rem",
    height: "1rem",
    width: "1rem",
  },
  arrowDownIcon: {
    height: "1rem",
    width: "0.75rem",
  },
  inputPropsIcon: {
    height: "1rem",
    width: "1rem",
  },
};

export default styles;
