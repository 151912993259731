import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import Layout from "./containers/Layout";
import Users from "./containers/Users";
import Clients from "./containers/Clients";
import Locations from "./containers/Locations";
import Pickers from "./containers/Pickers";
import Parcels from "./containers/Parcels";
import ProtectedRoute from "./components/ProtectedRoute";
import AddUser from "./containers/AddUser";
import AddClient from "./containers/Clients/AddClient";
import AddPicker from "./containers/AddPicker";
import EditUser from "./containers/EditUser";
import EditClient from "./containers/Clients/EditClient";
import { getUserRole } from "./utils/commonHelpers";
import EditPickerLocation from "./containers/EditPicker";
import AddParcel from "./containers/AddParcel";
import EditParcel from "./containers/EditParcel";
import IssueParcel from "./containers/IssueParcel";
import UnderConstruction from "./components/UnderConstruction";
import LocationForm from "./containers/Locations/LocationForm";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { VerifyUser } from "./VerifyUser";

function App() {
  const userRole = getUserRole();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email" element={<VerifyUser />} />
        <Route element={<Layout />}>
          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <Clients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-client"
            element={
              <ProtectedRoute>
                <AddClient />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-client/:id"
            element={
              <ProtectedRoute adminRoute={false}>
                <EditClient />
              </ProtectedRoute>
            }
          />

          <Route path="/locations" element={<Locations />} />
          <Route
            path="/add-location"
            element={
              <ProtectedRoute>
                <LocationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-location/:id"
            element={
              <ProtectedRoute adminRoute={false}>
                <LocationForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute adminRoute={false}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-user"
            element={
              <ProtectedRoute
                adminRoute={!["Client Admin", "Admin"].includes(userRole)}
              >
                <AddUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-user/:id"
            element={
              <ProtectedRoute adminRoute={false}>
                <EditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pickers"
            element={
              <ProtectedRoute adminRoute={false}>
                <Pickers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-picker"
            element={
              <ProtectedRoute adminRoute={false}>
                <AddPicker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/parcels"
            element={
              <ProtectedRoute adminRoute={false}>
                <Parcels />
              </ProtectedRoute>
            }
          />

          <Route
            path="/add-parcel"
            element={
              <ProtectedRoute adminRoute={false}>
                <AddParcel />
              </ProtectedRoute>
            }
          />

          <Route
            path="/edit-parcel/:id"
            element={
              <ProtectedRoute adminRoute={false}>
                <EditParcel />
              </ProtectedRoute>
            }
          />

          <Route
            path="/issue-parcel/:id"
            element={
              <ProtectedRoute adminRoute={false}>
                <IssueParcel />
              </ProtectedRoute>
            }
          />

          <Route
            path="/edit-picker/:id"
            element={
              <ProtectedRoute adminRoute={false}>
                <EditPickerLocation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute adminRoute={false}>
                <UnderConstruction />
              </ProtectedRoute>
            }
          />
          <Route
            path="/help-center"
            element={
              <ProtectedRoute adminRoute={false}>
                <UnderConstruction />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
