const styles = {
  container: {
    height: "4.25rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  recordCountText: {
    color: "#344054",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
  button: {
    border: "1px solid",
    borderRadius: "4px",
    textTransform: "none",
    borderColor: "#CBD5E1",
    color: "#475569",
    fontWeight: 600,
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
  },
};

export default styles;
