import axios, { AxiosError, isAxiosError } from "axios";
import { ILoginResponse } from "../Login/types";
import { NavigateFunction } from "react-router-dom";

export const setLocalStorageData = (data: ILoginResponse) => {
  localStorage.setItem("id", `${data.data.user.id}`);
  localStorage.setItem("email", data.data.user.email);
  localStorage.setItem("firstName", data.data.user.firstName);
  localStorage.setItem("lastName", data.data.user.lastName);
  localStorage.setItem("phone", data.data.user.phone);
  localStorage.setItem("imageUrl", data.data.user.imageUrl);
  localStorage.setItem("token", data.data.token);
  localStorage.setItem("roleId", data.data.user.roleId);
  localStorage.setItem("roleName", data.data.user.role.name);
  localStorage.setItem("clientId", data.data.user.clientId);
  localStorage.setItem("locationId", data.data.user.locationId);
};

export const getAccessToken = () => localStorage.getItem("token");
export const getUserName = () =>
  `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`;

export const getUserRole = () => localStorage.getItem("roleName") ?? "";
export const getUserId = () => localStorage.getItem("id");
export const getUserClientId = () => localStorage.getItem("clientId");

export const getApiErrorMessage = (error: Error) => {
  if (axios.isAxiosError(error) && error.response) {
    const data = error.response?.data as {
      errors: { message: string; status: number };
      message: string;
      error: string;
    };

    return data.errors.message as string;
  }
  return error.message;
};


export const clearLocalStorage = () => {
  localStorage.removeItem("id");
  localStorage.removeItem("email");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("phone");
  localStorage.removeItem("imageUrl");
  localStorage.removeItem("token");
  localStorage.removeItem("roleId");
  localStorage.removeItem("roleName");
  localStorage.removeItem("clientId");
  localStorage.removeItem("locationId");
};

export function onTokenError(error: unknown, navigate: NavigateFunction) {
  if (isAxiosError(error as Error)) {
    const err = error as AxiosError;
    if (err.response?.status === 401) {
      clearLocalStorage();
      navigate("/");
    }
  }
}
