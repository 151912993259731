import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import MyPickPointer from "../assets/mypick-portier.svg";
import Warstwa from "../assets/warstwa.svg";
import InputField from "../components/InputField";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import styles from "./styles";
import LogIn from "../assets/log-in.svg";
import ChevronRight from "../assets/chevron-right.svg";
import getAPIUrl from "../config";
import { useNavigate } from "react-router-dom";
import {
  getApiErrorMessage,
} from "../utils/commonHelpers";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { putApi } from "../utils/apis";
import { useState } from "react";
import Toast from "../components/Toast";
import toastStyles from "../components/Toast/styles";

export const ForgotPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");
  const navigate = useNavigate();

  const validationSchema = object({
    email: string({ required_error: "Please provide an email" }).email({
      message: "Please provide a valid email address",
    }),
  });

  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = await putApi(
        `${getAPIUrl()}/api/v1/users/forgot-password`,
        data
      );

      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);

      }
    }
  });

  return (
    <Grid sx={styles.container}>
      <img
        src={MyPickPointer}
        alt="my-pick-pointer"
        style={styles.myPickPointer}
      />
      <Grid sx={styles.loginFormContainer}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => i18next.changeLanguage(e.target.value)}
          defaultValue={i18next.language}
        >
          <FormControlLabel value="en" control={<Radio />} label="EN" />
          <FormControlLabel value="pl" control={<Radio />} label="PL" />
        </RadioGroup>
        <img src={Warstwa} alt="warstwa" style={styles.warstwa} />
        <Typography sx={styles.loginHeaderText}>myPick  Forgot Password</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testid="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <form onSubmit={onSubmit} style={styles.formContainer}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  id="email"
                  label={t("email")}
                  placeholder={t("email")}
                  type="text"
                  showCross
                  handleCrossClick={() => setValue("email", "")}
                  error={!!errors.email?.message}
                  errorMessage={errors.email?.message}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              sx={styles.loginButtonContainer}
            >
              <Grid sx={styles.loginButton}>
                <Typography sx={styles.loginText}>{t("forgotPassword")}</Typography>
                <img alt="login-icon" src={LogIn} style={styles.loginInIcon} />
              </Grid>
            </Button>
          </form>
        )
        }
        <Typography sx={styles.problemsText}>{t("anyProblems")}</Typography>

        <Button
          sx={styles.helpButton}
          endIcon={<img alt="chevron-right" src={ChevronRight} />}
        >
          {t("weCanHelpYou")}
        </Button>
        <Toast
          id="forgot-password-success"
          open={successToast}
          message={toastSuccessMsg}
          severity="success"
          onClose={() => setSuccessToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          alertStyles={toastStyles.toastText}
        />
        <Toast
          id="forgot-password-error"
          open={errorToast}
          message={toastErrorMsg}
          severity="error"
          onClose={() => setErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          alertStyles={toastStyles.toastText}
        />
      </Grid>
    </Grid>
  );
};
