import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IPicker } from "../Pickers/types";
import { IUser } from "../Users/types";
import getAPIUrl from "../../config";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import {
  getApi,
  postApiWithAuthFormData,
  putApiWithAuth,
} from "../../utils/apis";
import { ParcelStatus } from "../AddParcel/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { object, string, z, array } from "zod";
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Button,
  Stack,
} from "@mui/material";
import InputField from "../../components/InputField";
import CustomSelect from "../../components/SelectField";
import Toast from "../../components/Toast";
import toastStyles from "../../components/Toast/styles";
import Header from "../Header";
import styles from "../AddParcel/styles";
import TrashIcon from "../../assets/trash.svg";
import CameraIcon from "../../assets/camera.svg";
import { IParcelRes } from "../Parcels/types";
import { IParcelEditFormData, IParcelImage } from "./types";

const EditParcel = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");
  const [pickupLocations, setPickupLocations] = useState<IPicker[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [deletedImages, setDeletedImages] = useState<Array<string>>([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const validationSchema = object({
    pickupLocationId: string().min(1, "Please select pickup location"),
    userId: string().min(1, "Please select User"),
    deliveryCompany: string().min(1, {
      message: "Please add delivery company",
    }),
    parcelStatus: z.enum([
      ParcelStatus.InStorage,
      ParcelStatus.Issued,
      ParcelStatus.Returned,
    ]),
    parcelImage: array(
      object({ id: string(), name: string(), imageUrl: string() })
    ),
    parcelImages: array(
      object({
        id: string().optional(),
        imageFile: z.instanceof(File, { message: "Please upload profile pic" }),
      })
    ),
  });

  const defaultValues: IParcelEditFormData = {
    pickupLocationId: "",
    userId: "",
    deliveryCompany: "",
    parcelStatus: ParcelStatus.InStorage,
    parcelImage: [
      {
        id: "",
        name: "",
        imageUrl: "",
      },
    ],
    parcelImages: [],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const handleCancel = () => {
    reset(defaultValues);
    navigate("/parcels");
  };

  const uploadFiles = async (filesToUpload: File[]) => {
    try {
      const formData = new FormData();
      filesToUpload.map((rec) => formData.append("files", rec));

      const res = await postApiWithAuthFormData(
        `${getAPIUrl()}/api/v1/files/parcel-images`,
        formData
      );
      return res.data;
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
      }
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    const { parcelImage, parcelImages, ...rest } = data;
    let imagesArray = parcelImage;
    imagesArray = imagesArray?.map((rec) =>
      rec.id && deletedImages.includes(rec.id)
        ? { ...rec, status: "Delete" }
        : rec
    );
    const newImages = parcelImages ? parcelImages.filter((rec) => !rec.id) : [];

    if (newImages.length) {
      const uploadedImages: IParcelImage[] = await uploadFiles(
        newImages.map((rec) => rec.imageFile)
      );
      const updatedImages = uploadedImages.map((rec) => ({
        name: rec.name,
        imageUrl: rec.imageUrl.replace("/parcel-images/", ""),
      }));

      imagesArray = imagesArray
        ? [...imagesArray, ...updatedImages]
        : updatedImages;
    }

    try {
      const res = await putApiWithAuth(`${getAPIUrl()}/api/v1/parcels/${id}`, {
        ...rest,
        parcelImage: imagesArray,
      });

      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      reset(defaultValues);
      setTimeout(() => {
        setLoading(false);
        navigate("/parcels");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        const usersData = await getApi(
          `${getAPIUrl()}/api/v1/users/name?roleName=Picker`
        );
        setUsers(usersData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
          setLoading(false);
        }
      }
    };
    const getLocations = async () => {
      try {
        const locationsData = await getApi(
          `${getAPIUrl()}/api/v1/pickup-locations`
        );
        setPickupLocations(locationsData.data.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
          setLoading(false);
        }
      }
    };

    const getParcelDetails = async () => {
      try {
        const parcelDetailsData: IParcelRes = await getApi(
          `${getAPIUrl()}/api/v1/parcels/${id}`
        );

        const getImages = parcelDetailsData.data.parcelImage.length
          ? await Promise.all(
              parcelDetailsData.data.parcelImage.map(
                async (rec) =>
                  await fetch(
                    `${getAPIUrl()}/api/v1/files/view/parcel-images?file=${
                      rec.imageUrl
                    }`
                  ).then(async (response) => {
                    const imageBlob = await response.blob();
                    return {
                      id: rec.id,
                      imageFile: new File([imageBlob], rec.name),
                    };
                  })
              )
            )
          : [];

        reset({
          pickupLocationId: parcelDetailsData.data.pickupLocationId,
          userId: parcelDetailsData.data.userId,
          deliveryCompany: parcelDetailsData.data.deliveryCompany,
          parcelStatus: ParcelStatus[parcelDetailsData.data.parcelStatus],
          parcelImage: parcelDetailsData.data.parcelImage,
          parcelImages: getImages,
        });
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
          setLoading(false);
        }
      }
    };

    getUsers();
    getLocations();
    getParcelDetails();
  }, [id, reset]);

  const usersList =
    users &&
    users.map((user) => ({
      value: user.id,
      name: `${user.firstName} ${user.lastName}`,
    }));

  const parcelStatusesList = Object.entries(ParcelStatus).map((rec) => ({
    name: rec[0],
    value: rec[1],
  }));

  const watchFields = watch(["userId", "parcelImages"]);

  return (
    <Grid sx={styles.container}>
      <Header
        name="Edit Parcel"
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.parcelDetailsContainer}>
        <Typography sx={styles.subHeading}>Parcel details</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testId="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <Box component="form" sx={styles.formContainer} onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 10 }}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="userId"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder="User"
                      items={usersList}
                      error={!!errors.userId?.message}
                      errorMessage={errors.userId?.message}
                      label="ser"
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="pickupLocationId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="pickupLocationId"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder="Pickup Location"
                      items={
                        watchFields[0] === ""
                          ? []
                          : pickupLocations
                              .filter((rec) => rec.userId === watchFields[0])
                              .map((loc) => ({
                                name: loc.location.name,
                                value: loc.id,
                              }))
                      }
                      error={!!errors.pickupLocationId?.message}
                      errorMessage={errors.pickupLocationId?.message}
                      label="Pickup Location"
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="deliveryCompany"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="deliveryCompany"
                      label="Delivery Company"
                      placeholder="Delivery Company"
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("deliveryCompany", "")}
                      error={!!errors.deliveryCompany?.message}
                      errorMessage={errors.deliveryCompany?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="parcelStatus"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="parcelStatus"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder="Status"
                      items={parcelStatusesList}
                      error={!!errors.parcelStatus?.message}
                      errorMessage={errors.parcelStatus?.message}
                      label="Status"
                      disabled
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                name="parcelImages"
                control={control}
                render={({ field }) => (
                  <Button
                    component="label"
                    startIcon={
                      <img src={CameraIcon} alt="" style={styles.trashIcon} />
                    }
                    sx={styles.addImageButton}
                  >
                    Add photo from drive
                    <input
                      type="file"
                      id="upload-profile-picture"
                      accept=".png,.jpg,.jpeg"
                      hidden
                      multiple
                      onChange={(e) =>
                        field.onChange(
                          e.target.files && [
                            ...Array.from(field.value ?? []),
                            ...[...Array.from(e.target.files as FileList)].map(
                              (rec) => ({
                                imageFile: rec,
                              })
                            ),
                          ]
                        )
                      }
                    />
                  </Button>
                )}
              />
            </Grid>

            {watchFields[1] && watchFields[1].length ? (
              <Box sx={styles.addedImagesContainer}>
                {watchFields[1].map((rec, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={styles.addedImageContainer}
                  >
                    <Grid sx={styles.imageGrid}>
                      <img
                        alt={`parcel-${index}`}
                        src={URL.createObjectURL(rec.imageFile)}
                        style={styles.addedImage}
                      />
                      <Grid>
                        <Typography sx={styles.imageName}>
                          {rec.imageFile.name}
                        </Typography>
                        <Typography sx={styles.imageSize}>
                          {`${(rec.imageFile.size / (1024 * 1024)).toFixed(
                            2
                          )} MB`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <img
                      alt=""
                      src={TrashIcon}
                      style={styles.trashIcon}
                      onClick={() => {
                        setValue(
                          "parcelImages",
                          watchFields[1] &&
                            watchFields[1].filter((it) => it !== rec)
                        );
                        if (rec.id) {
                          setDeletedImages([...deletedImages, rec.id]);
                        }
                      }}
                    />
                  </Stack>
                ))}
              </Box>
            ) : undefined}

            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 10 }}
              sx={styles.buttonsGrid}
            >
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={styles.cancelButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={styles.saveButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Toast
        id="edit-parcel-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="edit-parcel-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default EditParcel;
