const styles = {
  container: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  parcelDetailsContainer: {
    marginLeft: "2rem",
    marginRight: "2rem",
    padding: "2rem",
    border: "1px solid",
    borderColor: "#E2E8F0",
    borderRadius: "4px",
  },
  subHeading: {
    fontWeight: 600,
    color: "#334155",
    lineHeight: "2rem",
    fontSize: "1.125rem",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20rem",
    height: "10rem",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  detail: {
    display: "flex",
    flexDirection: "column",
  },
  detailHeaderText: {
    color: "#64748B",
    fontWeight: 600,
    lineHeight: "1rem",
    fontSize: "0.625rem",
  },
  detailValueText: {
    color: "#1B1C1B",
    fontWeight: 400,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
  },
  buttonsGrid: {
    paddingTop: "3rem",
  },
  cancelButton: {
    borderColor: "#CBD5E1",
    borderRadius: "small",
    color: "#475569",
    fontWeight: 600,
    lineHeight: "1.5rem",
    fontSize: "0.875rem",
    textTransform: "none",
  },
  saveButton: {
    borderColor: "#006AFF",
    borderRadius: "small",
    fontWeight: 600,
    lineHeight: "1.5rem",
    fontSize: "0.875rem",
    textTransform: "none",
    color: "#FEFEFE",
  },
};

export default styles;
