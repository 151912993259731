// ClientForm.tsx
import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import InputField from "../../components/InputField";
import styles from "./styles";
import { IFormValues } from "./types";
import { useTranslation } from "react-i18next";

interface ClientFormProps {
  control: Control<IFormValues>;
  errors: any; // Adjust this type as per your error handling mechanism
  defaultValues: any;
  onSubmit: () => void;
  handleCancel: () => void;
  isEditing: boolean;
}

const ClientForm: React.FC<ClientFormProps> = ({
  control,
  errors,
  defaultValues,
  onSubmit,
  handleCancel,
  isEditing,
}) => {
  const { t } = useTranslation();

  return (
    <Box component="form" sx={styles.formContainer} onSubmit={onSubmit}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 10 }}>
        <Grid item md={6} xs={12}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="name"
                label={t("name")}
                placeholder={t("name")}
                type="text"
                showCross
                fullWidth
                size="small"
                handleCrossClick={() => field.onChange("")}
                error={!!errors.name?.message}
                errorMessage={errors.name?.message}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="address"
                label={t("companyAddress")}
                placeholder={t("companyAddress")}
                type="text"
                showCross
                fullWidth
                size="small"
                handleCrossClick={() => field.onChange("")}
                error={!!errors.address?.message}
                errorMessage={errors.address?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="city"
                label={t("city")}
                placeholder={t("city")}
                type="text"
                showCross
                fullWidth
                size="small"
                handleCrossClick={() => field.onChange("")}
                error={!!errors.city?.message}
                errorMessage={errors.city?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="postalCode"
                label={t("postalCode")}
                placeholder={t("postalCode")}
                type="text"
                showCross
                fullWidth
                size="small"
                handleCrossClick={() => field.onChange("")}
                error={!!errors.postalCode?.message}
                errorMessage={errors.postalCode?.message}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name="companyTaxId"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="companyTaxId"
                label={t("companyTaxId")}
                placeholder={t("companyTaxId")}
                type="text"
                showCross
                fullWidth
                size="small"
                handleCrossClick={() => field.onChange("")}
                error={!!errors.companyTaxId?.message}
                errorMessage={errors.companyTaxId?.message}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="phone"
                label={t("phone")}
                placeholder={t("phone")}
                type="text"
                showCross
                fullWidth
                size="small"
                handleCrossClick={() => field.onChange("")}
                error={!!errors.phone?.message}
                errorMessage={errors.phone?.message}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="email"
                label={t("email")}
                placeholder={t("email")}
                type="text"
                showCross
                fullWidth
                size="small"
                handleCrossClick={() => field.onChange("")}
                error={!!errors.email?.message}
                errorMessage={errors.email?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 10 }}
        sx={styles.buttonsGrid}
      >
        <Grid item md={6} xs={12}>
          <Button
            variant="outlined"
            fullWidth
            sx={styles.cancelButton}
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
        </Grid>

        <Grid item md={6} xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={styles.saveButton}
          >
            {t("save")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientForm;
