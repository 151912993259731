import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import styles from "./styles";
import { useForm } from "react-hook-form";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import getAPIUrl from "../../config";
import { postApiWithAuth } from "../../utils/apis";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import { useNavigate } from "react-router-dom";
import toastStyles from "../../components/Toast/styles";
import Toast from "../../components/Toast";
import Header from "../Header";
import ClientForm from "./ClientForm";
import { IFormValues } from "./types";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const AddClient = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const validationSchema = object({
    name: string().min(1, "Please provide Company Name"),
    companyTaxId: string().min(1, "Please provide Company TaxId"),
    email: string({ required_error: "Please provide an email" }).email({
      message: "Please provide a valid email address",
    }),
    phone: string().regex(/^\d{10}$/, "Please provide a mobile number"),
    address: string().min(1, "Please provide Address"),
    postalCode: string().min(1, "Please provide Postal Code"),
    city: string().min(1, "Please provide City"),
  });

  const defaultValues: IFormValues = {
    name: "",
    address: "",
    city: "",
    postalCode: "",
    companyTaxId: "",
    email: "",
    phone: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const res = await postApiWithAuth(`${getAPIUrl()}/api/v1/clients`, data);
      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      reset(defaultValues);
      setTimeout(() => {
        setLoading(false);
        navigate("/clients");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  const handleCancel = () => {
    reset(defaultValues);
    navigate("/clients");
  };

  return (
    <Grid sx={styles.container}>
      <Header
        name={t("newClient")}
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.clientDetailsContainer}>
        <Typography sx={styles.subHeading}>{t("companyDetails")}</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testId="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <ClientForm
            control={control}
            errors={errors}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            handleCancel={handleCancel}
            isEditing={false}
          />
        )}
      </Box>
      <Toast
        id="add-client-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="add-client-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default AddClient;
