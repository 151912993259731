import styles from "./styles";
import MyPicIcon from "../../assets/my-pick.svg";
import ClientsIcon from "../../assets/clients-icon.svg";
import ActiveClientIcon from "../../assets/active-client-icon.svg";
import UsersIcon from "../../assets/users-icon.svg";
import ActiveUsersIcon from "../../assets/active-users.svg";
import LocationsIcon from "../../assets/map-pin.svg";
import ActiveLocationIcon from "../../assets/active-map-pin.svg";
import PickersIcon from "../../assets/package-check.svg";
import ActivePickersIcon from "../../assets/active-package-check.svg";
import PackageIcon from "../../assets/package-search.svg";
import SettingsIcon from "../../assets/settings.svg";
import HelpIcon from "../../assets/help-circle.svg";
import DownArrow from "../../assets/down.svg";
import { Grid, Typography, Box, Button } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  clearLocalStorage,
  getAccessToken,
  getUserName,
  getUserRole,
} from "../../utils/commonHelpers";
import LogoutIcon from "../../assets/log-out.svg";
import { socket } from "../../socket/instance";
import { useTranslation } from "react-i18next";

const SideBar = () => {
  const { pathname } = useLocation();
  const username = getUserName();
  const token = getAccessToken();

  const navigate = useNavigate();

  const userRole = getUserRole();

  const { t } = useTranslation();

  const handleLogOut = () => {
    socket.emit("disconnected", {
      token,
    });
    clearLocalStorage();
    navigate("/");
  };

  const data = [
    {
      id: 1,
      title: t("manage"),
      items:
        userRole !== "Picker"
          ? [
            ...(userRole !== "Client Admin" ? [{
              text: t("clients"),
              icon: ClientsIcon,
              activeIcon: ActiveClientIcon,
              url: "/clients",
            }] : []),
            {
              text: t("locations"),
              icon: LocationsIcon,
              activeIcon: ActiveLocationIcon,
              url: "/locations",
            },
            {
              text: t("users"),
              icon: UsersIcon,
              activeIcon: ActiveUsersIcon,
              url: "/users",
            },
            {
              text: t("pickerLocations"),
              icon: PickersIcon,
              activeIcon: ActivePickersIcon,
              url: "/pickers",
            },
          ]
          : [
            {
              text: t("pickerLocations"),
              icon: PickersIcon,
              activeIcon: ActivePickersIcon,
              url: "/pickers",
            },
          ],
    },
    {
      id: 2,
      title: t("services"),
      items: [
        {
          text: t("parcel"),
          icon: PackageIcon,
          activeIcon: PackageIcon,
          url: "/parcels",
        },
      ],
    },
    {
      id: 3,
      title: t("admin"),
      items: [
        {
          text: t("settings"),
          icon: SettingsIcon,
          activeIcon: SettingsIcon,
          url: "/settings",
        },
        {
          text: t("helpCenter"),
          icon: HelpIcon,
          activeIcon: HelpIcon,
          url: "/help-center",
        },
      ],
    },
  ];

  return (
    <Box style={styles.container}>
      <img alt="my-pic-icon" src={MyPicIcon} style={styles.myPickIcon} />
      <Box
        style={{
          height: "4rem",
          border: "1px solid",
          borderColor: "#E2E8F0",
          borderRadius: "4px",
          padding: "1rem",
          display: "flex",
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{ gap: "0.375rem", display: "flex", flexDirection: "column" }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              color: "#0F172A",
              lineHeight: "1.25rem",
              fontSize: "1rem",
              textTransform: "capitalize",
            }}
          >
            {username}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              height: "0.75rem",
              alignItems: "center",
              gap: "0.125rem",
            }}
          >
            <img
              alt=""
              src={ActiveClientIcon}
              style={{ height: "0.75rem", width: "0.75rem" }}
            />
            <Typography
              sx={{
                color: "#64748B",
                fontWeight: 400,
                fontSize: "0.725rem",
              }}
            >
              Black Castle Park
            </Typography>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              height: "0.75rem",
              alignItems: "center",
              gap: "0.125rem",
            }}
          >
            <img
              alt=""
              src={ActiveLocationIcon}
              style={{ height: "0.75rem", width: "0.75rem" }}
            />
            <Typography
              sx={{
                color: "#64748B",
                fontWeight: 400,
                fontSize: "0.725rem",
              }}
            >
              56 Stark str
            </Typography>
          </Grid>
        </Grid>
        <img
          alt="down-arrow"
          src={DownArrow}
          style={{ height: "1rem", width: "1rem" }}
        />
      </Box>
      <Box>
        {data.map((rec) => (
          <Grid sx={styles.menuContainer} key={rec.id}>
            <Typography sx={styles.menuTitleText}>{rec.title}</Typography>
            {rec.items.map((sidebarItem, index) => (
              <NavLink
                style={styles.navLinkContainer(
                  pathname.includes(sidebarItem.url)
                )}
                to={sidebarItem.url}
                key={index}
              >
                <img
                  alt="clients-icon"
                  src={
                    pathname.includes(sidebarItem.url)
                      ? sidebarItem.activeIcon
                      : sidebarItem.icon
                  }
                  style={styles.menuIcon}
                />
                <Typography
                  sx={styles.menuText(pathname.includes(sidebarItem.url))}
                >
                  {sidebarItem.text}
                </Typography>
              </NavLink>
            ))}
          </Grid>
        ))}
      </Box>
      <Button sx={styles.logOutButton} onClick={handleLogOut}>
        <img alt="logout-icon" src={LogoutIcon} style={styles.menuIcon} />
        <Typography sx={styles.menuText(false)}>{t("logOut")}</Typography>
      </Button>
    </Box>
  );
};

export default SideBar;
