import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import styles from "./styles";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/InputField";
import { useEffect, useState } from "react";
import getAPIUrl from "../../config";
import { getApi, putApiWithAuth } from "../../utils/apis";
import { getApiErrorMessage } from "../../utils/commonHelpers";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../components/SelectField";
import { ILocation } from "../Locations/types";
import { IUser } from "../Users/types";
import { IClient } from "../Clients/types";
import { IPickerRes } from "../Pickers/types";
import Header from "../Header";
import toastStyles from "../../components/Toast/styles";
import Toast from "../../components/Toast";

const EditPickerLocation = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<IClient[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");

  const navigate = useNavigate();

  const { id } = useParams();

  const validationSchema = object({
    locationId: string().min(1, "Please select Location Name"),
    userId: string().min(1, "Please select Picker Name"),
    buildingName: string().min(1, "Please provide Building Name"),
    floorNumber: string().min(1, "Please provide floor number"),
    identificationNumber: string().min(
      1,
      "Please provide identification number"
    ),
  });

  const defaultValues = {
    clientId: "",
    locationId: "",
    userId: "",
    buildingName: "",
    floorNumber: "",
    identificationNumber: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const watchFields = watch("clientId");

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const res = await putApiWithAuth(
        `${getAPIUrl()}/api/v1/pickup-locations/${id}`,
        data
      );
      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      setTimeout(() => {
        setLoading(false);
        navigate("/pickers");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const getClients = async () => {
      try {
        const clientsData = await getApi(`${getAPIUrl()}/api/v1/clients/name`);
        setClients(clientsData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    const getLocations = async () => {
      try {
        const locationsData = await getApi(
          `${getAPIUrl()}/api/v1/locations/name`
        );
        setLocations(locationsData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    const getUsers = async () => {
      try {
        const usersData = await getApi(
          `${getAPIUrl()}/api/v1/users/name?roleName=Picker`
        );
        setUsers(usersData.data);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };
    const getPickerDetails = async () => {
      try {
        const pickerDetailsData: IPickerRes = await getApi(
          `${getAPIUrl()}/api/v1/pickup-locations/${id}`
        );
        reset({
          locationId: pickerDetailsData.data.locationId,
          userId: pickerDetailsData.data.userId,
          buildingName: pickerDetailsData.data.buildingName,
          floorNumber: pickerDetailsData.data.floorNumber,
          identificationNumber: pickerDetailsData.data.identificationNumber,
          clientId: pickerDetailsData.data.location.clientId,
        });
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          const errorMsg = getApiErrorMessage(error);
          setErrorToast(true);
          setErrorToastMsg(errorMsg);
        }
      }
    };

    getClients();
    getLocations();
    getUsers();
    getPickerDetails();
  }, [id, reset]);

  const clientsList =
    clients &&
    clients.map((client) => ({ name: client.name, value: client.id }));

  const usersList =
    users &&
    users.map((user) => ({
      name: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));

  const handleCancel = () => {
    reset(defaultValues);
    navigate("/pickers");
  };

  return (
    <Grid sx={styles.container}>
      <Header
        name="Edit Picker"
        setErrorToast={setErrorToast}
        setSuccessToast={setSuccessToast}
        setErrorToastMsg={setErrorToastMsg}
        setSuccessToastMsg={setSuccessToastMsg}
      />
      <Box sx={styles.pickerDetailsContainer}>
        <Typography sx={styles.subHeading}>Picker details</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testId="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <Box component="form" sx={styles.formContainer} onSubmit={onSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 10 }}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="clientId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="clientId"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      size="small"
                      fullWidth
                      placeholder="Client Name"
                      items={clientsList}
                      error={!!errors.clientId?.message}
                      errorMessage={errors.clientId?.message}
                      label="Client Name"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="locationId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="locationId"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder="Location Name"
                      items={
                        watchFields === ""
                          ? []
                          : locations
                              .filter((rec) => rec.clientId === watchFields)
                              .map((location) => ({
                                name: location.name,
                                value: location.id,
                              }))
                      }
                      error={!!errors.locationId?.message}
                      errorMessage={errors.locationId?.message}
                      label="Location Name"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      id="userId"
                      onChange={(e) => field.onChange(e.target.value)}
                      size="small"
                      fullWidth
                      placeholder="Picker Name"
                      items={usersList}
                      error={!!errors.userId?.message}
                      errorMessage={errors.userId?.message}
                      label="Picker Name"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="buildingName"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="buildingName"
                      label="Building Name"
                      placeholder="Building Name"
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("buildingName", "")}
                      error={!!errors.buildingName?.message}
                      errorMessage={errors.buildingName?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="floorNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="floorNumber"
                      label="Floor Number"
                      placeholder="Floor Number"
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() => setValue("floorNumber", "")}
                      error={!!errors.floorNumber?.message}
                      errorMessage={errors.floorNumber?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="identificationNumber"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      id="identificationNumber"
                      label="Identification Number"
                      placeholder="Identification Number"
                      type="text"
                      showCross
                      fullWidth
                      size="small"
                      handleCrossClick={() =>
                        setValue("identificationNumber", "")
                      }
                      error={!!errors.identificationNumber?.message}
                      errorMessage={errors.identificationNumber?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 10 }}
              sx={styles.buttonsGrid}
            >
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={styles.cancelButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={styles.saveButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Toast
        id="edit-picker-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="edit-picker-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};

export default EditPickerLocation;
