const styles = {
  container: {
    width: "15rem",
    borderRight: "1px solid",
    borderRightColor: "#CBD5E1",
    padding: "1rem",
    height: "100%",
  },
  myPickIcon: {
    height: "2.5rem",
    width: "7rem",
    paddingBottom: "1rem",
  },
  menuContainer: {
    paddingTop: "2rem",
  },
  menuTitleText: {
    color: "#64748B",
    fontWeight: "600",
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    paddingLeft: "1rem",
    paddingBottom: "0.5rem",
  },
  navLinkContainer: (activeMenu: boolean) => ({
    display: "flex",
    flexDirection: "row" as unknown as "row",
    height: "3rem",
    gap: "1rem",
    alignItems: "center",
    textDecoration: "none",
    paddingLeft: " 1rem",
    background: activeMenu ? "#32A9F90D" : undefined,
    border: activeMenu ? "2px solid" : undefined,
    borderColor: activeMenu ? "#006AFF" : undefined,
    borderRadius: activeMenu ? "4px" : undefined,
  }),
  menuIcon: {
    height: "1.5rem",
    width: "1.5rem",
  },
  menuText: (activeMenu: boolean) => ({
    color: activeMenu ? "#006AFF" : "#1B1C1B",
    fontWeight: "500",
    fontSize: "0.9rem",
    lineHeight: "1rem",
  }),
  logOutButton: {
    display: "flex",
    flexDirection: "row" as unknown as "row",
    height: "3rem",
    gap: "1rem",
    alignItems: "center",
    textDecoration: "none",
    paddingLeft: " 1rem",
    textTransform: "none",
    paddingBottom: "2rem",
    paddingTop: "8rem",
  },
};

export default styles;
