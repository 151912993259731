const styles = {
  container: {
    paddingTop: "10rem",
    paddingBottom: "5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    gap: "4rem",
    flexWrap: "wrap",
  },
  myPickPointer: {
    height: "30rem",
    width: "30rem",
  },
  loginFormContainer: {
    height: "37rem",
    width: "21rem",
    border: "1px solid",
    borderColor: "#E2E8F0",
    background: "#F8FAFC",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "2rem",
    gap: "1rem",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warstwa: {
    height: "8rem",
    width: "8rem",
  },
  loginHeaderText: {
    color: "#000000",
    fontWeight: 600,
    fontSize: "1.5rem",
  },
  showPasswordIcon: { height: "1rem", width: "1rem" },
  formContainer: { display: "grid", gap: "0.5rem" },
  loginButtonContainer: {
    width: "16.5rem",
    height: "3.6rem",
    textTransform: "none",
  },
  loginButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  loginText: { lineHeight: "1rem", fontWeight: 600, fontSize: "0.9rem" },
  loginInIcon: { height: "1.5rem", width: "1.5rem" },
  problemsText: {
    paddingTop: "1rem",
    lineHeight: "1rem",
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  helpButton: {
    textTransform: "none",
    lineHeight: "1rem",
    fontWeight: 600,
    fontSize: "0.9rem",
    color: "#006AFF",
  },
};

export default styles;
