import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import styles from "./styles";
import FilterIcons from "../../assets/filter-lines.svg";
import SearchIcon from "../../assets/search.svg";
import XCircle from "../../assets/x-circle.svg";
import InputField from "../InputField";
import { TableHeaderProps } from "./types";

const TableHeader = ({
  headerTitle,
  headerDesc,
  handleSearchChange,
  handleClearSearch,
  buttonName,
  searchValue,
  handleAdd,
  disableAdd,
}: TableHeaderProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={styles.stackContainer}
    >
      <Grid sx={styles.headerTitleGrid}>
        <Typography sx={styles.headerTitle}>{headerTitle}</Typography>
        <Typography sx={styles.headerDesc}>{headerDesc}</Typography>
      </Grid>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        sx={styles.searchFilterStack}
      >
        <Button
          startIcon={<img src={FilterIcons} alt="" style={styles.filterIcon} />}
          sx={styles.filterButton}
        >
          Filters
        </Button>
        <InputField
          id="search"
          placeholder="Label"
          showCross
          size="small"
          onChange={handleSearchChange}
          value={searchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <img
                    alt="search-icon"
                    src={SearchIcon}
                    style={styles.inputPropsIcon}
                  />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={styles.inputPropsIcon}
                  onClick={handleClearSearch}
                >
                  {
                    <img
                      alt="x-circle"
                      src={XCircle}
                      style={styles.inputPropsIcon}
                    />
                  }
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleAdd}
          disabled={disableAdd}
        >
          {buttonName}
        </Button>
      </Stack>
    </Stack>
  );
};

export default TableHeader;
