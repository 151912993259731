export enum ParcelStatus {
  InStorage = "InStorage",
  Issued = "Issued",
  Returned = "Returned",
}

export interface IParcelImage {
  name: string;
  imageUrl: string;
}

export interface IParcelFormData {
  pickupLocationId: string;
  userId: string;
  deliveryCompany: string;
  parcelStatus: ParcelStatus;
  parcelImage: IParcelImage[];
  parcelImages: File[];
}
