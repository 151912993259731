import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import MyPickPointer from "../assets/mypick-portier.svg";
import Warstwa from "../assets/warstwa.svg";
import InputField from "../components/InputField";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import styles from "./styles";
import LogIn from "../assets/log-in.svg";
import ChevronRight from "../assets/chevron-right.svg";
import EyeOff from "../assets/eye-off.svg";
import { useState } from "react";
import { putApi } from "../utils/apis";
import getAPIUrl from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getApiErrorMessage,
} from "../utils/commonHelpers";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Toast from "../components/Toast";
import toastStyles from "../components/Toast/styles";
function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}
export const ResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [toastErrorMsg, setErrorToastMsg] = useState<string>("");
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [toastSuccessMsg, setSuccessToastMsg] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const query = useQuery();
  const token = query.get('token');
  const validationSchema = object({
    password: string({ required_error: "Please provide a password" }).min(1, {
      message: "Please provide a password",
    }),
    confirmPassword: string({ required_error: "Please provide a confirmPassword" }).min(1, {
      message: "Please provide a confirmPassword",
    }),
  });

  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: ""
    },
    resolver: zodResolver(validationSchema),
  });
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const res = await putApi(
        `${getAPIUrl()}/api/v1/users/reset-password?token=${token}`,
        data
      );
      setSuccessToastMsg(res.data.message);
      setSuccessToast(true);
      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setErrorToastMsg(errorMsg);
        setErrorToast(true);
        setLoading(false);
      }
    }
  });

  return (
    <Grid sx={styles.container}>
      <img
        src={MyPickPointer}
        alt="my-pick-pointer"
        style={styles.myPickPointer}
      />
      <Grid sx={styles.loginFormContainer}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => i18next.changeLanguage(e.target.value)}
          defaultValue={i18next.language}
        >
          <FormControlLabel value="en" control={<Radio />} label="EN" />
          <FormControlLabel value="pl" control={<Radio />} label="PL" />
        </RadioGroup>
        <img src={Warstwa} alt="warstwa" style={styles.warstwa} />
        <Typography sx={styles.loginHeaderText}>myPick Reset Password</Typography>
        {loading ? (
          <Typography sx={styles.spinner} data-testid="loader">
            <CircularProgress />
          </Typography>
        ) : (
          <form onSubmit={onSubmit} style={styles.formContainer}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  id="password"
                  label={t("password")}
                  placeholder={t("password")}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          sx={styles.showPasswordIcon}
                        >
                          {
                            <img
                              alt="eye-off"
                              src={EyeOff}
                              style={styles.showPasswordIcon}
                            />
                          }
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password?.message}
                  errorMessage={errors.password?.message}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  id="confirmPassword"
                  label={t("confirmPassword")}
                  placeholder={t("confirmPassword")}
                  type={showConfirmPassword ? "text" : "confirmPassword"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          sx={styles.showPasswordIcon}
                        >
                          {
                            <img
                              alt="eye-off"
                              src={EyeOff}
                              style={styles.showPasswordIcon}
                            />
                          }
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.confirmPassword?.message}
                  errorMessage={errors.confirmPassword?.message}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              sx={styles.loginButtonContainer}
            >
              <Grid sx={styles.loginButton}>
                <Typography sx={styles.loginText}>{t("resetPassword")}</Typography>
                <img alt="login-icon" src={LogIn} style={styles.loginInIcon} />
              </Grid>
            </Button>
          </form>
        )
        }
        <Typography sx={styles.problemsText}>{t("anyProblems")}</Typography>

        <Button
          sx={styles.helpButton}
          endIcon={<img alt="chevron-right" src={ChevronRight} />}
        >
          {t("weCanHelpYou")}
        </Button>
      </Grid>

      <Toast
        id="reset-password-success"
        open={successToast}
        message={toastSuccessMsg}
        severity="success"
        onClose={() => setSuccessToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
      <Toast
        id="reset-password-error"
        open={errorToast}
        message={toastErrorMsg}
        severity="error"
        onClose={() => setErrorToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        alertStyles={toastStyles.toastText}
      />
    </Grid>
  );
};
