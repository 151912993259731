import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import MyPickPointer from "../assets/mypick-portier.svg";
import Warstwa from "../assets/warstwa.svg";
import InputField from "../components/InputField";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import styles from "./styles";
import LogIn from "../assets/log-in.svg";
import ChevronRight from "../assets/chevron-right.svg";
import EyeOff from "../assets/eye-off.svg";
import { useState } from "react";
import { ILoginResponse } from "./types";
import postApi from "../utils/apis";
import getAPIUrl from "../config";
import { useNavigate } from "react-router-dom";
import {
  getApiErrorMessage,
  setLocalStorageData,
} from "../utils/commonHelpers";
import { socket } from "../socket/instance";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const validationSchema = object({
    email: string({ required_error: "Please provide an email" }).email({
      message: "Please provide a valid email address",
    }),
    password: string({ required_error: "Please provide a password" }).min(1, {
      message: "Please provide a password",
    }),
  });

  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res: ILoginResponse = await postApi(
        `${getAPIUrl()}/api/v1/auth/sign-in`,
        data
      );
      setLocalStorageData(res);

      socket.connect();
      socket.emit("newConnection", {
        token: res.data.token,
      });

      res.data.user.role.name === "Picker"
        ? navigate("/pickers")
        : res.data.user.role.name === "Client Admin" ?
          navigate("/locations") : navigate("/clients");
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = getApiErrorMessage(error);
        setError("password", {
          message: errorMsg,
        });
      }
    }
  });

  const handleClick = () => {
    navigate('/forgot-password');
  };

  return (
    <Grid sx={styles.container}>
      <img
        src={MyPickPointer}
        alt="my-pick-pointer"
        style={styles.myPickPointer}
      />
      <Grid sx={styles.loginFormContainer}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => i18next.changeLanguage(e.target.value)}
          defaultValue={i18next.language}
        >
          <FormControlLabel value="en" control={<Radio />} label="EN" />
          <FormControlLabel value="pl" control={<Radio />} label="PL" />
        </RadioGroup>
        <img src={Warstwa} alt="warstwa" style={styles.warstwa} />
        <Typography sx={styles.loginHeaderText}>myPick OPS Log In</Typography>
        <form onSubmit={onSubmit} style={styles.formContainer}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="email"
                label={t("login")}
                placeholder={t("login")}
                type="text"
                showCross
                handleCrossClick={() => setValue("email", "")}
                error={!!errors.email?.message}
                errorMessage={errors.email?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                id="password"
                label={t("password")}
                placeholder={t("password")}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        sx={styles.showPasswordIcon}
                      >
                        {
                          <img
                            alt="eye-off"
                            src={EyeOff}
                            style={styles.showPasswordIcon}
                          />
                        }
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password?.message}
                errorMessage={errors.password?.message}
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            sx={styles.loginButtonContainer}
          >
            <Grid sx={styles.loginButton}>
              <Typography sx={styles.loginText}>{t("login")}</Typography>
              <img alt="login-icon" src={LogIn} style={styles.loginInIcon} />
            </Grid>
          </Button>
          <Button
            sx={styles.forgotPassword}
            endIcon={<img alt="chevron-right" src={ChevronRight} />}
            onClick={handleClick}
          >
            {t("forgotPassword")}
          </Button>
        </form>


        <Typography sx={styles.problemsText}>{t("anyProblems")}</Typography>

        <Button
          sx={styles.helpButton}
          endIcon={<img alt="chevron-right" src={ChevronRight} />}
        >
          {t("weCanHelpYou")}
        </Button>
      </Grid>
    </Grid>
  );
};
