import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { ITableProps } from "./types";
import { ReactNode, useEffect, useState } from "react";
import { getApiErrorMessage, onTokenError } from "../../utils/commonHelpers";
import styles from "./styles";
import CustomPagination from "../Pagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useNavigate } from "react-router-dom";

function CustomTable<T>({
  page,
  setPage,
  fetchAgain,
  columns,
  initialSortField,
  rowKey,
  initialSortDirection,
  fetchTableData,
  searchValue,
  setErrorToastMsg,
  setErrorToast,
}: ITableProps<T>): JSX.Element {
  const [sortField, setsortField] = useState<keyof T>(initialSortField);
  const [sortDirection, setsortDirection] = useState<"asc" | "desc">(
    initialSortDirection ?? "desc"
  );
  const [tableData, setTableData] = useState<T[]>([]);
  const [tableDataCount, setTableDataCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const tableDataRes = await fetchTableData(
        page,
        searchValue,
        sortField,
        sortDirection
      );
      if (tableDataRes instanceof Error) {
        const errMsg = getApiErrorMessage(tableDataRes);
        onTokenError(tableDataRes, navigate);
        setErrorToastMsg(errMsg);
        setErrorToast(true);
        setLoading(false);
      } else {
        setTableData(tableDataRes.data);
        setTableDataCount(tableDataRes.count);
        setLoading(false);
      }
    };
    getData();
  }, [
    fetchAgain,
    fetchTableData,
    navigate,
    page,
    searchValue,
    setErrorToast,
    setErrorToastMsg,
    sortDirection,
    sortField,
  ]);

  const handlePageChange = async (newPage: number) => {
    setPage(newPage);
    setLoading(true);
    const resultData = await fetchTableData(
      newPage,
      searchValue,
      sortField,
      sortDirection
    );

    if (resultData instanceof Error) {
      const errMsg = getApiErrorMessage(resultData);
      setErrorToastMsg(errMsg);
      setErrorToast(true);
      setLoading(false);
    } else {
      setTableData(resultData.data);
      setLoading(false);
    }
  };

  const handleSortingChange = async (field: keyof T) => {
    const order =
      field === sortField && sortDirection === "asc" ? "desc" : "asc";
    setsortField(field);
    setsortDirection(order);
    setLoading(true);
    const resultData = await fetchTableData(page, searchValue, field, order);
    if (resultData instanceof Error) {
      const errMsg = getApiErrorMessage(resultData);
      setErrorToastMsg(errMsg);
      setErrorToast(true);
      setLoading(false);
    } else {
      setTableData(resultData.data);
      setLoading(false);
    }
  };

  const renderSortIcon = (column: keyof T, sortable?: boolean) => {
    if (sortField === column) {
      return sortDirection === "asc" ? (
        <ArrowUpwardIcon sx={styles.arrowIcon} />
      ) : (
        <ArrowDownwardIcon sx={styles.arrowIcon} />
      );
    } else {
      return sortable ? (
        <>
          <ArrowUpwardIcon sx={styles.arrowDownIcon} />
          <ArrowDownwardIcon sx={styles.arrowDownIcon} />
        </>
      ) : null;
    }
  };

  return (
    <>
      {loading ? (
        <Typography sx={styles.spinner} data-testid="loader">
          <CircularProgress />
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow role="listbox" sx={styles.tableHeadRow}>
                {columns.map((column) => (
                  <TableCell key={column.title}>
                    {column.title ? (
                      <TableSortLabel
                        direction={sortDirection}
                        onClick={() => {
                          if (column.sortable) {
                            handleSortingChange(column.dataKey as keyof T);
                          }
                        }}
                        IconComponent={() =>
                          renderSortIcon(
                            column.dataKey as keyof T,
                            column.sortable
                          )
                        }
                        sx={styles.tableHeaderSort}
                      >
                        {column.title}
                      </TableSortLabel>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={styles.tableBody}>
              {tableData.map((row, rowIndex) => (
                <TableRow key={`${row[rowKey]}`}>
                  {columns.map((column, columnIndex) => {
                    const value = row[column.dataKey as keyof T];
                    const renderedContent =
                      typeof column.render === "function" ? (
                        column.render({
                          value,
                          row,
                          rowIndex,
                          columnIndex,
                        })
                      ) : (
                        <Typography sx={styles.tableBodyCellText}>
                          {value as ReactNode}
                        </Typography>
                      );

                    return (
                      <TableCell
                        sx={styles.tableBodyCell}
                        key={column.dataKey.toString()}
                        data-testid={`row-${rowIndex + 1}-column-${
                          columnIndex + 1
                        }`}
                      >
                        {renderedContent as ReactNode}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <CustomPagination
            totalCount={tableDataCount}
            page={page}
            pageSize={10}
            currentPageDataCount={tableData.length}
            handlePageChange={handlePageChange}
          />
        </TableContainer>
      )}
    </>
  );
}

export default CustomTable;
